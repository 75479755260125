import React, { useState, useEffect } from 'react';
import { FISH_INVENTORY_MUTATION, GET_FISH_INVENTORIES } from '../../queries/FistInventoryQuery';
import {useMutation } from '@apollo/client';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';

const SpeciesForm = ({ pageType, onClose }) => {
    const [fishInventoryMutation, { data, loading, error }] = useMutation(FISH_INVENTORY_MUTATION); 
    const initialFormData = {
        speciesName: '',
        sellerPrice: '',
        fishPhoto: null,
        buyersPrice:'',
    }

    const [formData, setFormData] = useState(initialFormData);
    const [imagePreview, setImagePreview] = useState('');
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {};
        let emptyFields = [];
    
        if (!formData.speciesName) emptyFields.push('Species Name');
        if (!formData.sellerPrice) emptyFields.push('Seller Price');
        if (!formData.buyersPrice) emptyFields.push('Buyer Price');
        if (!formData.fishPhoto) emptyFields.push('Fish Photo');
    
        if (emptyFields.length > 0) {
            ToastNotification({ message: `${emptyFields.join(', ')} are required`, type: 'error' });
        }
    
        setValidationErrors(errors);
        return emptyFields.length === 0;
    };
    
    const handleFishInventory = async () => {
        try {
            const { data } = await fishInventoryMutation({
                variables: {
                    speciesName: formData.speciesName,
                    sellerPrice: parseFloat(formData.sellerPrice),
                    buyerPrice: parseFloat(formData.buyersPrice),
                    image: formData.fishPhoto
                  }
            });
            ToastNotification({ message: data?.fishInventory.message, type: 'success' });
            setTimeout(() => {
                onClose();
              }, 1000);
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setFormData(prevState => ({ ...prevState, fishPhoto: file }));
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm() && pageType === 'add') {
            handleFishInventory();
        }
    };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50 ">
              <Toaster position="top-right" reverseOrder={false} />
            <div className="w-[80%] lg:w-[870px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 sm:pr-12 sm:py-11 sm:p-6 bg-white rounded-[10px]">
                <button className="absolute top-4 right-4" onClick={()=>onClose()}>
                    <img src="/images/times_icon.svg" alt="Close" />
                </button>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap sm:flex-nowrap gap-y-4 justify-between items-center border-b border-[#E5E7EB] pb-4 sm:pb-6 mb-4 sm:mb-6">
                        <div className='w-full sm:w-auto'>
                            <h3 className="font-bold text-lg sm:text-xl">{pageType === 'add' ? 'Add New Species' : 'Edit Species'}</h3>
                            <p className="text-[#4B5563] text-sm sm:text-base">
                                {pageType === 'add' ? 'Easily add new fish species' : 'Edit the details of the species'}
                            </p>
                        </div>
                        {pageType !== 'view' && (
                            <button type="submit" className="h-[35px] sm:h-auto sm:py-4 px-3 sm:px-6 text-xs bg-[#161941] rounded-md text-white font-normal sm:font-bold sm:text-base">
                                {loading ? (
                                    <div className="upload_loader" style={{marginLeft:"0%"}}></div>
                                    ) : (
                                        pageType === 'add' ? 'Add Species' : 'Save Changes'
                                    )} 
                            </button>
                        )}
                    </div>
                    <div className="flex gap-4 sm:gap-6 flex-wrap sm:flex-nowrap">
                        <div className="w-20 md:w-40 lg:w-48">
                            <div className="relative w-full h-20  md:h-40 lg:h-48 border-dotted border-[2px] border-[#161941] rounded-sm">
                                <input
                                    type="file"
                                    className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
                                    onChange={handleFileChange}
                                    disabled={pageType === 'view'}
                                />
                                {imagePreview ? (
                                    <img className="w-full h-full object-cover rounded-sm" src={imagePreview} alt="Fish" />
                                ) : (
                                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <img className='w-7 sm:w-auto' src="images/placeholder_img.svg" alt="Placeholder" />
                                    </span>
                                )}
                            </div>
                            <label className="block mt-2 text-center w-full text-[#6B7280] text-xs font-semibold tracking-[1px]">
                                Fish Photo
                            </label>
                        </div>
                        <div className="w-full md:w-[calc(100%-120px)] lg:w-[calc(100%-192px)]">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 sm:mb-6">
                                <div className='col-span-1 sm:col-span-2 lg:col-span-1'>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="speciesName">
                                        Species Name
                                    </label>
                                    <input
                                        className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                        type="text"
                                        placeholder="Name"
                                        name="speciesName"
                                        value={formData.speciesName}
                                        onChange={handleInputChange}
                                        disabled={pageType === 'view'}
                                    />
                                </div>
                                <div>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="sellerPrice">
                                        Seller Price
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="$111"
                                            name="sellerPrice"
                                            value={formData.sellerPrice}
                                            onChange={handleInputChange}
                                            disabled={pageType === 'view'}
                                        />
                                        <span className="absolute top-1/2 right-2 -translate-y-1/2 text-[#757575] text-sm tracking-[1px]">
                                            / lbs
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="sellerPrice">
                                        Buyer Price
                                    </label>
                                    <div className="relative">
                                        <input
                                            className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="$111"
                                            name="buyersPrice"
                                            value={formData.buyersPrice}
                                            onChange={handleInputChange}
                                            disabled={pageType === 'view'}
                                        />
                                        <span className="absolute top-1/2 right-2 -translate-y-1/2 text-[#757575] text-sm tracking-[1px]">
                                            / lbs
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SpeciesForm;
