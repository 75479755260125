import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EXPORT_ORDERS_QUERY, GET_ALL_ORDERS } from "../../queries/OrderQuery";
import FooterPage from "./FooterPage";
import InlineLoader from "../utils/InlineLoader";
import { useSelector } from "react-redux";

const OrderPage = () => {
  const { currentPage } = useSelector((state) => state.auth);
  const [openTab, setOpenTab] = useState("pending");
  const [page, setPage] = useState(currentPage);
  const [exportLoder, setExportLoader] = useState(false);
  const limit = 15;

  const { loading, error, data, fetchMore } = useQuery(GET_ALL_ORDERS, {
    variables: { page: page, limit, status: openTab },
  });
  const {
    data: exportData,
    loading: isLoading,
    error: fetchError,
  } = useQuery(EXPORT_ORDERS_QUERY, {
    variables: { status: openTab },
  });

  useEffect(() => {
    if (fetchMore) {
      fetchMore({
        variables: { page, limit, status: openTab },
      });
    }
  }, [page, openTab, fetchMore]);

  const { getAllOrders } = data || {};

  const handleClickFile = async () => {
    if (isLoading) return;
    if (fetchError) {
      console.error(fetchError);
      return;
    }
    if (exportData && exportData?.exportOrders) {
      setExportLoader(true);
      try {
        const response = await fetch(exportData?.exportOrders.path, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Access-Control-Allow-Origin": "*",
            "Cache-Control": "no-cache, no-store, must-revalidate",
            Pragma: "no-cache",
            Expires: "0",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to download file");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "exportedFile.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExportLoader(false);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };

  return (
    <>
      <div className="sm:pb-0 pt-0 xl:pb-0 p-4 lg:p-6 xl:p-12 sm:pt-2 xl:pt-2 bg-[#FAFBFF]">
        <div className="grid grid-cols-1 lg:grid-cols-1 mb-6 rounded-md overflow-hidden">
          <div className="relative">
            <div className="w-full">
              <div className="w-full border-b border-b-[#EDEDED] bg-white">
              <div className="w-full flex border-b border-[#ededed] sm:border-none  px-0 overflow-x-auto sm:w-[500px]  sm:px-8 runded-t-md overflow-hidden bg-white">
                <button
                  onClick={() => setOpenTab("pending")}
                  className={`w-1/2 sm:w-auto relative font-medium sm:text-xl  py-4 sm:py-4 px-4 sm:px-7  outline-none focus:shadow-outline-blue transition-all duration-300 ${
                    openTab === "pending"
                      ? "bg-transparent text-[#161941] dropline"
                      : "text-[#828282]"
                  }`}
                >
                  Pending
                </button>
                <button
                  onClick={() => setOpenTab("completed")}
                  className={`w-1/2 sm:w-auto relative font-medium sm:text-xl py-4 sm:py-4 px-4 sm:px-7 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                    openTab === "completed"
                      ? "bg-transparent text-[#161941] dropline"
                      : "text-[#828282]"
                  }`}
                >
                  Completed
                </button>
              </div>
              <div className="p-4 sm:p-0">
              <button class="button-custom w-full sm:w-auto flex items-center gap-2" onClick={handleClickFile}>
                {exportLoder ? (
                  <div
                    className="upload_loader"
                    style={{ marginLeft: "0%" }}
                  ></div>
                ) : (
                  <>
                    <img
                      src="/images/export_upload_icon.svg"
                      alt="icon"
                    />
                    Export
                  </>
                )}
              </button>
              </div>
              
              </div>
              
              <div
                className={`transition-all duration-300 bg-white ${
                  openTab === "pending" ? "block" : "hidden"
                }`}
              >
                <div className="bg-white h-[50vh] sm:h-[65vh] overflow-y-auto">
                  <div className="overflow-x-auto">
                    <table className="w-full min-w-[640px]">
                      <thead>
                        <tr>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order ID
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                            Buyer
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Amount
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Fish Ordered
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ||
                        error ||
                        getAllOrders?.orders?.length === 0 ? (
                          <tr>
                            <td colSpan={7} style={{ height: 450 }}>
                              {loading ? (
                                <InlineLoader />
                              ) : error ? (
                                <p className="error_text">
                                  Error: {error.message}
                                </p>
                              ) : (
                                <p className="error_text">
                                  <img src="/images/order_image.svg" alt=" " />
                                </p>
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {getAllOrders?.orders?.map((order) => (
                          <tr>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.orderId}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-8 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.buyerName}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">{`$${(
                                parseFloat(order?.Amount) || 0
                              ).toFixed(2)}`}</span>
                            </td>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.fistItemName}
                                {order?.totalItemsInOrder
                                  ? " + " + order?.totalItemsInOrder
                                  : ""}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                              <Link
                                to={`/orders/${openTab}-details/${order?.id}`}
                              >
                                <button className="text-base w-auto">
                                  <img
                                    src="/images/action_eye_icon.svg"
                                    alt=""
                                  />
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                className={`transition-all duration-300 bg-white ${
                  openTab === "completed" ? "block" : "hidden"
                }`}
              >
                <div className="bg-white h-[50vh] sm:h-[65vh] overflow-y-auto">
                  <div className="overflow-x-auto">
                    <table className="w-full min-w-[640px]">
                      <thead>
                        <tr>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order ID
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                            Buyer
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Amount
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Fish Ordered
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ||
                        error ||
                        getAllOrders?.orders?.length === 0 ? (
                          <tr>
                            <td colSpan={7} style={{ height: 450 }}>
                              {loading ? (
                                <InlineLoader />
                              ) : error ? (
                                <p className="error_text">
                                  Error: {error.message}
                                </p>
                              ) : (
                                <p className="error_text">
                                  <img src="/images/order_image.svg" alt=" " />
                                </p>
                              )}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {getAllOrders?.orders?.map((order) => (
                          <tr>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.orderId}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-8 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.buyerName}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">{`$${(
                                parseFloat(order?.Amount) || 0
                              ).toFixed(2)}`}</span>
                            </td>
                            <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                              <span className="font-medium text-base text-left">
                                {order?.fistItemName}
                              </span>
                            </td>
                            <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                              <Link
                                to={`/orders/${openTab}-details/${order?.id}`}
                              >
                                <button className="text-base w-auto">
                                  <img
                                    src="/images/action_eye_icon.svg"
                                    alt="View Details"
                                  />
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterPage
            currentPage={page}
            totalPages={getAllOrders?.totalPages}
            onPageChange={setPage}
            perPage={getAllOrders?.orders?.length}
            totalValue={getAllOrders?.total}
          />
        </div>
      </div>
    </>
  );
};

export default OrderPage;
