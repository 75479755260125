import React, { useEffect, useState } from 'react';
import SideBarPage from './SideBarPage';
import HeaderPage from './HeaderPage';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl } from '../../redux/slices/authSlice';

const CommonPage = ({ name, icon, children }) => {
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [isOverlayActive, setOverlayActive] = useState(false);
    const { showSideBar } = useSelector((state) => state.auth);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };

    const handleSideBar = () =>{
        setSidebarVisible(false)
    };


    return (
        <div className={`commonpage text-gray-800 font-inter ${showSideBar ? "active-sidebar" : ""} ${isOverlayActive ? "overlay-active" : ""}`}>
            <SideBarPage isSidebarVisible={isSidebarVisible} hideSidebar={hideSidebar} changeSideBar = {()=> handleSideBar()}/>
            <div className={`w-full lg:w-[calc(100%-256px)] main-content lg:ml-64 bg-[#FAFBFF] min-h-screen transition-all`}>
                <HeaderPage name={name} icon={icon} toggleSidebar={toggleSidebar} setOverlayActive={setOverlayActive} />
                {children}
            </div>
            {isSidebarVisible && (
                <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-40 lg:hidden sidebar-overlay" onClick={hideSidebar}></div>
            )}
            {isOverlayActive && (
                <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-[99]" onClick={() => setOverlayActive(false)}></div>
            )}
        </div>
    );
};

export default CommonPage;
