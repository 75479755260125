import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, setUser } from '../redux/slices/authSlice';
import {LOGIN_USER} from '../queries/AuthQuery';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../utils/ToastNotification';

const Login = () => {
  const [login, { data, loading, error }] = useMutation(LOGIN_USER);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await login({
        variables: {
          email: email,
          password: password
        }
      });

      const token = data.AdminLoginResponseogin.token;
      const user = data.AdminLoginResponseogin;
      dispatch(setCredentials({ token }));
      dispatch(setUser(user));
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000); 
  
    } catch (err) {
      ToastNotification({ message: err.message, type: 'error' });
    }
  };


  return (
    <div className="bg-white flex items-center h-screen justify-center">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <a href="#" className="static sm:fixed top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img className="mx-auto sm:mx-0 w-[110px] xl:w-[120px]" src="/images/logo_new.png" alt="" />
          </a>            
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px] text-[#4F4F4F]">Login</h2>
            <p className="text-[#4F4F4F] text-[16px]">Enter your details to login to your account</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-full relative mb-[10px]">
              <input 
                className="text-sm sm:text-sm md:text-base xl:text-lg w-full border-2 border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[70px]"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="absolute -translate-y-1/2 top-1/2 left-5">
                <img className="w-[22px] xl:w-auto" src="/images/mail_icon.svg" alt="" />
              </span>
            </div>
            <div className="w-full relative">
              <input 
                className="placeholder:text-[#BDBDBD] text-sm sm:text-sm md:text-base xl:text-lg h-[45px] w-full border-2 border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] sm:h-[50px] xl:h-[70px]"
                type={showPassword? "text":"password"}
                placeholder="*****************"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="absolute -translate-y-1/2 top-1/2 left-5">
                <img className="w-[22px] xl:w-auto" src="/images/password_icon.svg" alt="" />
              </span>
              <span className="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
                <img src={showPassword?"/images/eye_icon.svg":"/images/hide-password.svg"} alt="" onClick={()=>setShowPassword(!showPassword)}/>
              </span>
            </div>
            <div className="w-full text-right mt-2">
              <a href="/forgot-password" className="forgot-password text-primary text-base xl:text-xl">Forgot Password ?</a>
            </div>
            <div className="w-full mt-[15px] xl:mt-[34px]">
              <button type="submit" className="w-full rounded-[10px] font-bold bg-[#161941] text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px] xl:h-[70px]">
                {loading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "LOGIN"
                    )}
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src="/images/new-image.png" alt="" />
      </div>
    </div>
  );
};

export default Login;
