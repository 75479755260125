import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_ADMIN_BUYER_DETAIL, GET_ADMIN_BUYER_ORDERS, GET_ADMIN_BUYERS } from '../../queries/BuyerQuery';
import { Link, useParams } from 'react-router-dom';
import InlineLoader from '../utils/InlineLoader';
import ToastNotification from '../../utils/ToastNotification';
import { Toaster } from 'react-hot-toast';
import moment from 'moment';

const BuyersDetailPage = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [openTab, setOpenTab] = useState("pending");
  const limit = 15;
  const  { loading, error, data, refetch } = useQuery(GET_ADMIN_BUYER_DETAIL, {
    variables: { id },
    onCompleted: (data) => {
      ToastNotification({ message: 'Buyers info fetched successfully', type: 'success' });
    },
    onError: (err) => {
      ToastNotification({ message: err.message, type: 'error' });
    }
  });

  const  { loading: orderLoading, error: orderError, data: orderData } = useQuery(GET_ADMIN_BUYER_ORDERS, {
    variables: { page:page, limit:limit, status:openTab, buyerId: id },
  });

  // useEffect(() => {
  //   const fetchBuyersDetails = async () => {
  //     try {
  //       await loadBuyerDetail();
  //     } catch (err) {
  //       console.error("Failed to fetch buyer detail requests", err);
  //     }
  //   };

  //   fetchBuyersDetails();
  // }, [loadBuyerDetail]);

  // useEffect(() => {
  //   const fetchBuyersOrders = async () => {
  //     try {
  //       await loadBuyerOrder();
  //     } catch (err) {
  //       console.error("Failed to fetch buyer orders", err);
  //     }
  //   };

  //   fetchBuyersOrders();
  // }, [loadBuyerOrder]);

  const { personalInfo, companyInfo } = data?.getAdminBuyerDetail || {};
  const { getAdminBuyerOrders } = orderData || {}

  return (
    (
      <>
        {loading || error || !data ? (
          <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
            {loading ? (
              <InlineLoader />
            ) : error ? (
              <p className='error_text'>Error: {error.message}</p>
            ) : (
              <p className='error_text'>No data available</p>
            )}
          </div>
        ) : (
          <section className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
            <Toaster position="top-right" reverseOrder={false} />
            <article className="bg-white p-4 sm:p-5 lg:px-9 lg:py-7">
              <header className="mb-4 sm:mb-7">
                <h3 className="text-lg font-semibold lg:text-[22px] text-[#727272]">
                  Personal Information
                </h3>
                <p className="text-xs sm:text-sm text-[#4F4F4F]">
                  <span className="font-medium">Added on:</span> { moment(personalInfo?.addedOn).format('MM/DD/YYYY')}
                </p>
              </header>
              <div className="flex w-full items-start gap-4">
                <div className="w-full">
                  <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-4 gap-4 sm:gap-6 mb-4 sm:mb-6">
                    <div>
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="text"
                        placeholder="Henry Estward"
                        id="name"
                        value={personalInfo?.name}
                      />
                    </div>
                    <div>
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="email"
                        placeholder="abcde@gmail.com"
                        id="email"
                        value={personalInfo?.email}
                      />
                    </div>
                    <div>
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="ein"
                      >
                        EIN
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="text"
                        placeholder="AA 11 A 1234"
                        id="ein"
                        value={personalInfo?.ein}
                      />
                    </div>
                    <div>
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="mobile"
                      >
                        Mobile Number
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                        type="tel"
                        placeholder="+91 1234567890"
                        id="mobile"
                        value={`${personalInfo?.phoneNumber?.dialCode} ${personalInfo?.phoneNumber?.number}`}
                      />
                    </div>
                  </section>
                  <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-6">
                    <div>
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="role"
                      >
                        Title / Role
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                        type="text"
                        placeholder="Role"
                        id="role"
                        value={personalInfo?.role}
                      />
                    </div>
                    <div className="xl:col-span-2">
                      <label
                        className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        htmlFor="address"
                      >
                        Address
                      </label>
                      <input
                        className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="text"
                        placeholder="Address"
                        id="address"
                        value={personalInfo?.address}
                      />
                    </div>
                  </section>
                </div>
              </div>
            </article>

            <article className="bg-white p-4 sm:p-5 lg:px-9 lg:py-7 my-5 lg:mt-10 lg:mb-12">
              <header className="mb-4 sm:mb-5 lg:mb-6 xl:mb-11">
                <h3 className="text-lg font-semibold lg:text-[22px] text-[#727272]">
                  Company Information
                </h3>
              </header>
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-3 sm:gap-5">
                <div className="col-span-2 flex flex-wrap md:flex-nowrap w-full items-center gap-4">
                  <aside className="w-20 h-20 md:w-[130px] md:h-[130px] xl:w-[156px] xl:h-[156px]">
                  {companyInfo?.logo ?
                   <img
                      className="w-full h-full object-cover rounded-md"
                      src={companyInfo?.logo}
                      alt=""
                    />:<div className='shimmer' style={{width:"130px", height:"130px"}}></div>}
                  </aside>
                  <div className="w-full md:w-[calc(100%-156px)]">
                    <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-6 mb-4 sm:mb-6">
                      <div>
                        <label
                          className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                          htmlFor="companyName"
                        >
                          Company Name
                        </label>
                        <input
                          className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                          type="text"
                          placeholder="TACO BELLS"
                          id="companyName"
                          value={companyInfo?.name}
                        />
                      </div>
                      <div>
                        <label
                          className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                          htmlFor="companyWebsite"
                        >
                          Company’s Website
                        </label>
                        <input
                          className="border-none placeholder-text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                          type="url"
                          placeholder="tacobells.co.in"
                          id="companyWebsite"
                          value={companyInfo?.website}
                        />
                      </div>
                    </section>
                    <section className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                      <div>
                        <label
                          className="text-sm mb-0 font-semibold tracking-[1px] text-[#757575]"
                          htmlFor="targetSpecies"
                        >
                          Interested Target Species
                        </label>
                        <div className="flex items-center h-[54px] gap-3">
                          {companyInfo?.species?.map((specie) =>
                            <button
                              className="py-2 sm:py-3 px-4 sm:px-5 rounded-md bg-[#3D4289] text-xs sm:font-bold tracking-[1px] text-white"
                            >
                              {specie}
                            </button>
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div className="flex w-full items-start mb-10 xl:mb-0 gap-4">
                  <div className="w-full">
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="taxCertificate"
                    >
                      Tax exempt certificate
                    </label>
                    <aside className="w-24 h-20 sm:w-[156px] sm:h-[125px]">
                    {companyInfo?.certificate ?
                      <a href={companyInfo?.certificate} target="_blank" rel="noopener noreferrer">
                      <img
                        className="w-full h-full object-cover rounded-tl-[10px] rounded-tr-[10px]"
                        src="/images/pdf_images.png"
                      />
                    </a>
                      :
                      <div className='shimmer' style={{width:"156px", height:"156px"}}></div>}
                      <span className="text-[#161941] text-[10px] bg-[#F0F1FF] rounded-b-[10px] block text-center font-medium w-full py-2">
                      <input value = {companyInfo?.certificate} style={{display:"flex", flexWrap:"wrap"}}/>
                      </span>
                    </aside>
                  </div>
                </div>
              </div>
            </article>

            <article className="bg-white p-4 sm:p-5 lg:px-9 lg:py-7 my-5 lg:mt-10 lg:mb-12">
              <header className="mb-6 xl:mb-11 flex justify-between items-center">
                <h3 className="text-base sm:text-lg font-semibold lg:text-[22px] text-[#727272]">
                  Buyer’s Orders
                </h3>
                <a href={`/buyers/orders/${id}`} className="text-[#161941] text-sm sm:text-base sm:text-[22px] font-semibold">
                  See all
                </a>
              </header>
              <div className="bg-white h-[45vh] sm:h-[50vh] lg:h-[65vh] overflow-y-auto">
                <div className="overflow-x-auto">
                  <table className="w-full min-w-[900px]">
                    <thead>
                      <tr>
                        <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                          Order ID
                        </th>
                        <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                          Fish Type
                        </th>
                        <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                          Amount
                        </th>
                        <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                          Order Placed
                        </th>
                        <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {orderLoading || orderError || !orderData?.getAdminBuyerOrders?.orders.length ? (
                          <tr>
                            <td colSpan={7} style={{ height: 450 }}>
                              {orderLoading ? (
                                <InlineLoader />
                              ) : orderError ? (
                                <p className='error_text'>Error: {orderError.message}</p>
                              ) : (
                                <p className='error_text flex-wrap' >
                                <img src="/images/buyers_image.svg" alt=" " />
                                <b className='font-semibold text-[22px] text-gray-800 w-full text-center block mt-4'>No Orders</b>
                                </p> 
                              )}
                            </td>
                          </tr>
                        ) : ""}
                      {getAdminBuyerOrders?.orders?.map((order)=>
                       <tr>
                       <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{order?.orderId}</span>
                       </td>
                       <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {order?.fistItemName}
                         </span>
                       </td>
                       <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{`$${(parseFloat(order?.Amount) || 0).toFixed(2)}`}</span>
                       </td>
                       <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {moment(order?.orderPlaced).utc().format("MM/DD/yyyy")}
                           <div className="text-xs text-[#828282] block pl-4 pt-1"> {order?.orderPlaced?.split(' ')[1]}</div>
                         </span>
                       </td>
                       <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                        <Link to={`/orders/${openTab}-details/${order?.id}`}>
                          <button className="text-base w-auto">
                            <img src="/images/action_eye_icon.svg" alt="View" />
                          </button>
                        </Link>
                       </td>
                     </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
          </section>
        )}
      </>
    )
  );
};

export default BuyersDetailPage;
