import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../redux/slices/authSlice";

const FooterPage = ({ currentPage, totalPages, onPageChange, perPage, totalValue }) => {
  const { currentUrl } = useSelector((state)=> state.auth);
  const dispatch = useDispatch();

  const getBaseUrl = (url) => {
    const urlParts = url.split('/');
    if (!isNaN(urlParts[urlParts.length - 1])) {
      urlParts.pop();
    }
    return urlParts.join('/');
  };
  const pathSegments = currentUrl.split('/');
  const specificSegment = pathSegments[1];

  useEffect(()=>{
    dispatch(setCurrentPage(1));
  },[specificSegment])

  useEffect(()=>{
    dispatch(setCurrentPage(currentPage));
  },[currentPage])

  const baseUrl = getBaseUrl(currentUrl);

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex flex-wrap sm:flex-nowrap items-center justify-center gap-y-4 sm:justify-between mt-6">
      <p className="text-[#161941] w-full sm:w-auto text-center sm:text-left text-sm font-normal">
        Showing {currentPage} to {perPage || 0} of {totalValue || 0}
      </p>
      <ul className="flex items-center gap-6">
        <li>
          <button onClick={handlePrev} disabled={currentPage === 1}>
            <img src="/images/angle_left.svg" alt="Previous" />
          </button>
        </li>
        {[...Array(totalPages)].map((_, index) => (
          <li key={index}>
            <Link
              className={`w-[28px] h-[28px] rounded-md ${
                currentPage === index + 1 ? "bg-[#161941] text-white" : ""
              } flex items-center justify-center text-[14px]`}
              to={`${baseUrl}/${index + 1}`}
              onClick={() => onPageChange(index + 1)}
            >
              {index + 1}
            </Link>
          </li>
        ))}
        <li>
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            <img src="/images/angle_right.svg" alt="Next" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default FooterPage;
