import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { useState } from "react";
import FooterPage from './FooterPage';
import InlineLoader from '../utils/InlineLoader';
import { useSelector } from 'react-redux';
import { GET_SELLER_CATCHES } from '../../queries/SellerQuery';
import moment from 'moment';


const SellerCatchesPage = () =>{
    const { id } = useParams();
    const { currentPage } = useSelector((state)=> state.auth);
    const [page, setPage] = useState(currentPage);
    const [searchString, setSearchString] = useState("blank");
    const limit = 15;
    
    const { loading: loadingCatches, error: errorCatches, data: dataCatches, refetch } = useQuery(GET_SELLER_CATCHES, {
        variables: {
          page: page,
          limit: 15,
          sellerId: id,
          search: "blank",
        },
      });
  
    useEffect(() => {
      refetch();
    }, [page, refetch]);
  
    const getSellerCatches = dataCatches?.getSellerCatches || [];

  
    return(
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-0 sm:pt-2 xl:pt-2 bg-[#FAFBFF]">
        <div className="grid grid-cols-1 lg:grid-cols-1 mb-6">
          <div className="w-full border-b border-b-[#EDEDED] p-4 sm:py-[15px] sm:px-[30px] bg-white flex items-center justify-between">
            <div className="relative w-full sm:w-[300px]">
              <input
                className="text-xs font-medium px-9 w-full h-[40px] bg-[#FDFDFD] border border-solid border-[#E0E0E0] rounded-[10px]"
                type="search"
                placeholder="Search by name"
                onChange={(e) => setSearchString(e.target.value)}
              />
              <span className="absolute top-1/2 -translate-y-1/2 left-[15px]">
                <img src="/images/search_icon.svg" alt="" />
              </span>
            </div>
          </div>
          <div className="bg-white h-[45vh] sm:h-[55vh] lg:h-[65vh] mb-0 overflow-y-auto">
            <div className="overflow-x-auto">
              <table className="w-full min-w-[1000px]">
                <thead>
                  <tr>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">
                      Fish Type
                    </th>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">
                      Seller
                    </th>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                      Weight
                    </th>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">
                      Tag Number
                    </th>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">
                      Date
                    </th>
                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loadingCatches || errorCatches || getSellerCatches?.sellerCatches?.length === 0 &&
                    <tr>
                      <td colSpan={7} style={{ height: 450 }}>
                          {loadingCatches ? (
                            <InlineLoader />
                          ) : errorCatches ? (
                            <p className='error_text'>Error: {errorCatches.message}</p>
                          ) : (
                            <p className='error_text' >
                              <img src="/images/catch_image.svg" alt=" " />
                            </p>
                          )}
                      </td>
                    </tr>
                  }
  
                  {getSellerCatches?.sellerCatches?.map((catchItem) =>
                    <tr>
                      <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                        <div className="flex items-center gap-[20px]">
                          <a href="" className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-[10px] bg-gray-200">
                            {loadingCatches ?
                              <div className='shimmer w-full h-full rounded-[10px] object-cover block'></div>
                              : <img
                                src={catchItem?.image}
                                alt=""
                                className="w-full h-full rounded-[10px] object-cover block"
                              />
                            }
                          </a>
                          <span className="font-medium text-base text-left">{catchItem?.name}</span>
                        </div>
                      </td>
                      <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                        <span className="font-medium text-base text-left">{catchItem?.sellerName}</span>
                      </td>
                      <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                        <span className="font-medium text-base text-left">{catchItem?.weight+ "lbs"}</span>
                      </td>
                      <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                        <span className="font-medium text-base text-left">{catchItem?.tagNumber}</span>
                      </td>
                      <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                        <span className="font-medium text-base text-left">{moment(catchItem?.dateTime).format('MM/DD/YYYY')}
                          {/* <div className="text-xs text-[#828282] block pl-4 pt-1">10:12 AM</div> */}
                        </span>
                      </td>
                      <td className="py-3 sm:py-4 px-11 border-b border-b-[#EDEDED] text-right">
                        <Link to={`/catch-of-the-day/details/${catchItem.id}`}>
                          <button className="text-base w-auto">
                            <img src="/images/action_eye_icon.svg" alt="" />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <FooterPage
            currentPage={page}
            totalPages={getSellerCatches?.totalPages}
            onPageChange={setPage}
            perPage={getSellerCatches?.sellerCatches?.length}
            totalValue = {getSellerCatches?.total}
          />
        </div>
      </div>
    );
}
export default SellerCatchesPage;