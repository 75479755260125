import { gql } from '@apollo/client';

export const GET_SUB_ADMINS = gql`
    query GetSubadmins($page: Int!, $limit: Int!, $search: String!) {
    getSubadmins(page: $page, limit: $limit, search: $search) {
      total
      perPage
      currentPage
      totalPages
      subadmins {
        id
        firstName
        lastName
        email
        image
        createdAt
      }
    }
  }`
;


export const ADD_SUBADMIN = gql`
  mutation AddSubadmin($lastName: String!, $firstName: String!, $email: String!, $image: Upload) {
    addSubadmin(input: { lastName: $lastName, firstName: $firstName, email: $email, image: $image }) {
      message
    }
  }
`;

export const GET_SUBADMIN = gql`
  query GetSubadmin($id: ID!) {
    getSubadmin(id: $id) {
      id
      firstName
      lastName
      email
      image
      createdAt
    }
  }
`;

export const EDIT_SUBADMIN = gql`
  mutation EditSubadmin($id: ID!, $firstName: String!, $lastName: String!, $oldImageUrl: String!, $newImageFile: Upload ) {
    editSubadmin(id: $id, input: {firstName: $firstName, lastName: $lastName, oldImageUrl: $oldImageUrl, newImageFile: $newImageFile}) {
      message
    }
  }
`;

export const DELETE_SUBADMIN = gql`
  mutation DeleteSubadmin($id: ID!) {
    deleteSubadmin(id: $id) {
      message
    }
  }
`;