import React, { useEffect, useState } from 'react';
import { DELETE_SUBADMIN, GET_SUB_ADMINS } from '../../queries/SubAdminQuery';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import FooterPage from './FooterPage';
import AddSubAdmin from './AddSubAdmin';
import InlineLoader from '../utils/InlineLoader';
import EditViewSubAdminPage from './EditViewSubAdminPage';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';
import moment from 'moment';

const SubAdminPage = () => {
    const { currentPage } = useSelector((state) => state.auth);
    const [page, setPage] = useState(currentPage);
    const [searchTerm, setSearchTerm] = useState("blank");
    const [openSubadmin, setOpenSubadmin] = useState(false);
    const [pageType, setPageType] = useState('');
    const [subAdminId, setSubAdminId] = useState('');
    const [openDeletePopup, setOpenDeletePopup]= useState(false);
    const [showDropdown, setShowDropdown] = useState(null);
    const limit = 15;

    const { loading, error, data, refetch } = useQuery(GET_SUB_ADMINS, {
        variables: { limit, page, search: searchTerm },
    });
    const [deleteSubAdmin] = useMutation(DELETE_SUBADMIN);

    useEffect(() => {
        if (!openSubadmin) {
            refetch();
        }
    }, [openSubadmin, refetch])

    const toggleDropdown = (index) => {
        setShowDropdown(showDropdown === index ? null : index);
    };
    const { getSubadmins } = data || {}


    const handleOpenSubAdmin = (type, id) => {
        setOpenSubadmin(true);
        setPageType(type);
        setSubAdminId(id);
    }

    const handleClose = () => {
        setOpenSubadmin(false);
    }
    
    const deleteSubAdminPopup = async() =>{
        try {
            const { data } = await deleteSubAdmin({
                variables: { id : subAdminId }
            });
            refetch();
            setOpenDeletePopup(false);
            ToastNotification({ message: data.deleteSubadmin.message, type: 'success' });
        } catch (error) {
            console.error('Error occurred:', error);
            ToastNotification({ message: error.message, type: 'error' });
        }
    }
    const handleDeleteSubAdmin = async (id) => {
        setOpenDeletePopup(true)
        setSubAdminId(id);
    };


    return (
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
            <div className="grid grid-cols-1 lg:grid-cols-1 mb-6">
                <div className="w-full flex-wrap gap-y-4 sm:flex-nowrap border-b border-b-[#EDEDED] p-4 sm:py-[15px] sm:px-[30px] bg-white flex items-center justify-between">
                    <div className="relative w-full sm:w-[300px]">
                        <input
                            className="text-xs font-medium px-9 w-full h-[40px] bg-[#FDFDFD] border border-solid border-[#E0E0E0] rounded-[10px]"
                            type="search"
                            placeholder="Search by name"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <span className="absolute top-1/2 -translate-y-1/2 left-[15px]">
                            <img src="images/search_icon.svg" alt="Search Icon" />
                        </span>
                    </div>
                    <button class="h-10 sm:h-auto sm:py-[9px] px-5 sm:px-[27px] bg-[#161941] text-white text-sm sm:font-semibold rounded-[10px]" onClick={() => handleOpenSubAdmin("add", "")}>+ Add New</button>
                </div>
                <div className="bg-white h-[40vh] sm:h-[50vh] lg:h-[65vh] overflow-y-auto">
                    <table className="w-full min-w-[768px]">
                        <thead>
                            <tr>
                                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                    Name
                                </th>
                                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                    Email
                                </th>
                                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                    Date & Time
                                </th>
                                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading || error || getSubadmins?.subadmins?.length === 0 ? (
                                <tr>
                                    <td colSpan={7} style={{ height: 450 }}>
                                        {loading ? (
                                            <InlineLoader />
                                        ) : error ? (
                                            <p className='error_text'>Error: {error.message}</p>
                                        ) : (
                                            <p className='error_text' >
                                                <img src="/images/sub_admin.svg" alt=" " />
                                            </p>
                                        )}
                                    </td>
                                </tr>
                            ) :
                                getSubadmins?.subadmins?.map((item, index) => (
                                    <tr key={item?.id}>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <div className="flex items-center gap-[20px]">
                                                <img
                                                    className="w-10 h-10 sm:w-[50px] sm:h-[50px] rounded-[10px] object-cover block"
                                                    src={item?.image}
                                                    alt=''
                                                />
                                                <span className="font-medium text-sm sm:text-base text-left">
                                                    {item?.firstName} {item?.lastName}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">
                                                {item?.email}
                                            </span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">
                                            {moment.utc(item?.createdAt).local().format('MM/DD/YYYY')}
                                            </span>
                                            <p className='text-xs text-[#828282] block pl-4 pt-1'>{moment.utc(item?.createdAt).local().format('HH:mm:ss')}</p>
                                        </td>
                                        <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                                            <button
                                                className="text-sm sm:text-base w-auto relative"
                                                onClick={() => toggleDropdown(index)}
                                            >
                                                <img src="/images/action_btn.svg" alt="Action Button" />
                                                {showDropdown === index && (
                                                    <div className="absolute right-3 top-3 bg-white w-[174px] rounded-[10px] z-20 shadow-lg">
                                                        <ul>
                                                            <li className="cursor-pointer flex items-center justify-start font-medium text-sm gap-3 py-3 px-4 border-b border-[#F2F2F2]" onClick={() => handleOpenSubAdmin("view", item?.id)}>
                                                                <span>
                                                                    <img src="/images/action_view_icon.svg" alt="View Icon" />
                                                                </span>
                                                                View Info
                                                            </li>
                                                            <li className="cursor-pointer flex items-center justify-start font-medium text-sm gap-3 py-3 px-4 border-b border-[#F2F2F2]" onClick={() => handleOpenSubAdmin("edit", item?.id)}>
                                                                <span>
                                                                    <img src="/images/action_edit_info.svg" alt="Edit Icon" />
                                                                </span>
                                                                Edit Info
                                                            </li>
                                                            <li className="cursor-pointer flex items-center justify-start font-medium text-sm gap-3 py-3 px-4" onClick={() => handleDeleteSubAdmin(item?.id)}>
                                                                <span>
                                                                    <img src="/images/action_delete.svg" alt="Delete Icon" />
                                                                </span>
                                                                Delete
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <FooterPage
                    currentPage={page}
                    totalPages={getSubadmins?.totalPages}
                    onPageChange={setPage}
                    perPage={getSubadmins?.subadmins?.length}
                    totalValue={getSubadmins?.total}
                />
            </div>
            {openSubadmin && (
                pageType === "add" ? (
                    <AddSubAdmin
                        pageType={pageType}
                        onClose={handleClose}
                    />
                ) : (pageType === "edit" || pageType === "view" || pageType === "delete") && (
                    <EditViewSubAdminPage
                        pageType={pageType}
                        onClose={handleClose}
                        subAdminId={subAdminId}
                    />
                )
            )}
            {openDeletePopup ?
                <div class="fixed top-0 left-0  w-full h-full bg-black/50 z-50 ">
                    <div class="w-[340px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2  pt-5 pb-9 bg-white  rounded-[10px] text-center">
                        <span class="w-full"> <img class="mx-auto w-auto h-auto" src="/images/delete_icon_red.svg" alt="" /></span>
                        <h3 class="text-lg font-bold py-3">Please Confirm!</h3>
                        <p class="text-sm ">Are you sure you want to delete</p>
                        <div class="mt-6 flex gap-3 justify-center">
                            <button class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold" onClick={()=>setOpenDeletePopup(false)}>Cancel</button>
                            <button class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"onClick={deleteSubAdminPopup}>Delete</button>
                        </div>
                    </div>
                </div> : ""
            }
        </div>
    );
};

export default SubAdminPage;
