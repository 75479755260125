import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';

const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName;
  const token = localStorage.getItem('authToken');
  console.log(operationName,"==");
  
  let headers = {
    Authorization: token ? `Bearer ${token}` : '',
  };
  switch (operationName) {
    case 'GetSellerRequests':
      headers['X-GraphQL-Operation-Name'] = 'GetPendingRequest';
      break;
    case 'GetSellerRequestDetail':
      headers['X-GraphQL-Operation-Name'] = 'GetSellerRequestDetail';
      break;
    case 'ChangeStatus':
      headers['X-GraphQL-Operation-Name'] = 'ChangeStatus';
      break;
    case 'GetApprovedSellers':
      headers['X-GraphQL-Operation-Name'] = 'GetApprovedSellers';
      break;
    case 'GetSellerCatches':
      headers['X-GraphQL-Operation-Name'] = 'GetSellerCatches';
      break;
    case 'GetAdminCatches':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminCatches';
      break;
    case 'GetCatchDetail':
      headers['X-GraphQL-Operation-Name'] = 'GetCatchDetail';
      break;
    case 'catchChangeStatus':
      headers['X-GraphQL-Operation-Name'] = 'CatchChangeStatus';
      break;
    case 'GetAdminBuyerDetail':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminBuyerDetail';
      break;
    case 'GetAdminBuyers':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminBuyers';
      break;
    case 'ChangePassword ':
      headers['X-GraphQL-Operation-Name'] = 'ChangePassword';
      break;
    case 'GetCatchesByFish ':
      headers['X-GraphQL-Operation-Name'] = 'GetCatchesByFish';
      break;
    case 'EditProfile':
      headers['X-GraphQL-Operation-Name'] = 'EditAdmin';
      break;
    case 'FishInventory':
      headers['X-GraphQL-Operation-Name'] = 'AddFishInventory';
      break;
    case 'GetFishInventory':
      headers['X-GraphQL-Operation-Name'] = 'GetFishInventory';
      break;
    case 'GetAdminBuyerOrders':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminBuyerOrders';
      break;
    case 'GetAdminBuyers':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminBuyerOrders';
      break;
    case 'AdminBuyerOrderDetail':
      headers['X-GraphQL-Operation-Name'] = 'AdminBuyerOrderDetail';
      break;
    case 'Admin':
      headers['X-GraphQL-Operation-Name'] = 'GetAdminData';
      break;
    case 'ChangePaymentStatus':
      headers['X-GraphQL-Operation-Name'] = 'ChangePaymentStatus';
      break;
    case 'CompleteOrder':
        headers['X-GraphQL-Operation-Name'] = 'CompleteOrder';
        break;
    case 'ChangePassword':
      headers['X-GraphQL-Operation-Name'] = 'ChangePassword';
      break;
    case 'GetSubadmin':
      headers['X-GraphQL-Operation-Name'] = 'GetSubadmin';
      break;
    case 'EditSubadmin':
      headers['X-GraphQL-Operation-Name'] = 'EditSubadmin';
      break;
    case 'Admin':
      headers['X-GraphQL-Operation-Name'] = 'GetSubadmins';
      break;
    case 'DeleteSubadmin':
      headers['X-GraphQL-Operation-Name'] = 'DeleteSubadmin';
      break;
    case 'AddSubadmin':
        headers['X-GraphQL-Operation-Name'] = 'AddSubadmin';
        break;
    case 'HideUnhideFishInventory':
          headers['X-GraphQL-Operation-Name'] = 'HideUnhideFishInventory';
          break;
    case 'exportOrders':
          headers['X-GraphQL-Operation-Name'] = 'ExportOrders';
          break;
    case 'exportInventories':
            headers['X-GraphQL-Operation-Name'] = 'ExportFishInventories';
          break;
    case 'editAdminSideCatch':
          headers['X-GraphQL-Operation-Name'] = 'EditAdminSideCatch';
          break;
    default:
      headers['X-GraphQL-Operation-Name'] = 'DefaultOperation';
  }
  operation.setContext({
    headers
  });

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 403) {
    localStorage.removeItem('authToken');
    window.location.href = '/';
  }
});

const link = ApolloLink.from([authLink, errorLink,httpLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default client;
