import { ADMIN_CATCH_TRACK_INFO, CATCH_CHANGE_STATUS, CHANGE_PAYMENT_STATUS_QUERY, DELETE_ADMIN_CATCH, EDIT_ADMIN_SIDE_CATCH, GET_ALL_FISH_SPECIES, GET_CATCH_DETAIL, GET_PLACE_DETAILS, GET_PLACE_ID } from "../../queries/CatchQuery";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InlineLoader from "../utils/InlineLoader";
import { Toaster } from 'react-hot-toast';
import ToastNotification from "../../utils/ToastNotification";
import { useSelector } from "react-redux";
import fishIcon from '../../assets/mapFish.svg';
import moment from "moment";
import Select from "react-select";
import { GoogleMap, InfoWindow, InfoWindowF, LoadScript, LoadScriptNext, Marker, MarkerF } from "@react-google-maps/api";
import ImageModal from "../utils/ImageModal";

const CatchDetailPage = () => {
    const { id } = useParams();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const url = urlParts[urlParts.length - 2];
    const [showMap, setShowMap] = useState();
    const [openTab, setOpenTab] = useState(1);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [rejectSeller, setRejectSeller] = useState(false);
    const [disableEdit, setDisableEdit] = useState(true);
    const [approveSeller, setApproveSeller] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [image, setImage] = useState('');
    const [mapCenter, setMapCenter] = useState('');
    const [zoomLevel, setZoomLevel] = useState(5);
    const [storyData, setStoryData] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [mapForTrack, setMapForTrack] = useState(false);
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [imagePreview, setImagePreview] = useState([]);
    const [formData, setFormData] = useState({
        fishSpecies: '',
        totalWeight: '',
        tagNumber: '',
        dateTime: '',
        location: '',
        latitude: '',
        longitude: '',
        sellerPricePerLbs: '',
        buyerPricePerLbs: '',
        totalPrice: '',
        totalBuyerPrice: '',
        speciesId: '',
        story: '',
        oldPhotosUrls: [],
        newPhotosFiles: [],
    });
    const { currentPage } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const { loading, error, data, refetch } = useQuery(GET_CATCH_DETAIL, {
        variables: {
            id: id
        },
        onCompleted: (data) => {
            setFormData({
                fishSpecies: data?.catchDetail?.catchInfo?.fishSpecies || '',
                speciesId: data?.catchDetail?.catchInfo?.speciesId || '',
                totalWeight: data?.catchDetail?.catchInfo?.weight || '',
                tagNumber: data?.catchDetail?.catchInfo?.tagNumber || '',
                dateTime: data?.catchDetail?.catchInfo?.dateTime || '',
                totalPrice: data?.catchDetail?.catchInfo?.totalPrice || '',
                totalBuyerPrice: data?.catchDetail?.catchInfo?.buyerTotalPrice || '',
                sellerPricePerLbs: data?.catchDetail?.catchInfo?.sellerPricePerLbs || '',
                buyerPricePerLbs: data?.catchDetail?.catchInfo?.buyerPricePerLbs || '',
                location: data?.catchDetail?.catchInfo?.location || '',
                latitude: data?.catchDetail?.catchInfo?.latitude || '',
                longitude: data?.catchDetail?.catchInfo?.longitude || '',
                story: data?.catchDetail?.catchInfo?.story || '',
                oldPhotosUrls: data?.catchDetail?.catchInfo?.images || []
            });
            setStoryData(data?.catchDetail?.catchInfo?.story);
            setPaymentStatus(data?.catchDetail?.paymentStatus);
            setImagePreview(data?.catchDetail?.catchInfo?.images);
            setSelectedLocation(data?.catchDetail?.catchInfo?.location);
            setSelectedLocation({
                value: data?.catchDetail?.catchInfo?.location,
                label: data?.catchDetail?.catchInfo?.location,
            });
        },
    });


    const { loading: dataLoading, data: fishData } = useQuery(GET_ALL_FISH_SPECIES);

    const [editAdminSideCatch, { data: editData, loading: editLoading, error: editError }] = useMutation(EDIT_ADMIN_SIDE_CATCH);

    const { data: trackData, loading: trackLoading, error: trackError, refetch: refetchTrack } = useQuery(ADMIN_CATCH_TRACK_INFO, {
        variables: { tagNumber: formData?.tagNumber },
    });

    const [deleteCatch, { loading: deleteLoading, data: deleteData, error: deleteError }] = useLazyQuery(DELETE_ADMIN_CATCH);


    const { adminCatchesTrackInfo } = trackData || {}

    useEffect(() => {
        refetch();
        refetchTrack()
    }, [id, refetch, paymentStatus, refetchTrack])


    useEffect(() => {
        refetch();
    }, [refetch, editData, data])

    const [triggerQuery, { loading: statusLoading, error: statusError, data: statusData }] = useLazyQuery(CATCH_CHANGE_STATUS, {
        onCompleted: (data) => {
            ToastNotification({ message: 'Status changed successfully', type: 'success' });
            setTimeout(() => {
                navigate(`/catch-of-the-day/${currentPage}`);
            }, 1000);
        },
    });


    const [getPaymentStatus, { loading: paymentLoading, error: errorLoading, data: paymentData }] = useLazyQuery(CHANGE_PAYMENT_STATUS_QUERY, {
        variables: { id: id, status: "Paid" },
        onCompleted: (data) => {
            setPaymentStatus(data?.catchDetail?.paymentStatus);
            ToastNotification({ message: 'Payment status updated successfully', type: 'success' });
            if (url !== "fish-inventory") {
                setTimeout(() => {
                    navigate(`/orders/${currentPage}`);
                }, 1000);
            }
        },
        onError: (err) => {
            ToastNotification({ message: err.message, type: 'error' });
        }
    });

    const catchDetail = data?.catchDetail;

    useEffect(() => {
        if (url === "fish-details-2") {
            setOpenTab(2);
        }
    }, [id])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const parsedValue = parseFloat(value) || 0;

        if (name === "story") {
            setStoryData(value);
        } else if (name === "totalWeight" || name === "sellerPricePerLbs" || name === "buyerPricePerLbs") {
            const weightValue = name === "totalWeight" ? parsedValue : parseFloat(formData.totalWeight) || 0;
            const sellerPricePerLbs = name === "sellerPricePerLbs" ? parsedValue : parseFloat(formData.sellerPricePerLbs) || 0;
            const buyerPricePerLbs = name === "buyerPricePerLbs" ? parsedValue : parseFloat(formData.buyerPricePerLbs) || 0;

            setFormData({
                ...formData,
                totalWeight: weightValue,
                sellerPricePerLbs: name === "sellerPricePerLbs" ? parsedValue : formData.sellerPricePerLbs,
                buyerPricePerLbs: name === "buyerPricePerLbs" ? parsedValue : formData.buyerPricePerLbs,
                totalPrice: weightValue * sellerPricePerLbs,
                totalBuyerPrice: weightValue * buyerPricePerLbs
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prevState => ({
                    ...prevState,
                    oldPhotosUrls: [...prevState.oldPhotosUrls, reader.result],
                    newPhotosFiles: [...(prevState.newPhotosFiles || []), file]
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleApproveSeller = (value) => {
        setApproveSeller(value);
    }

    const handleRejectSeller = (value) => {
        setRejectSeller(value);
    }

    const handleChangeStatus = async (status) => {
        triggerQuery({ variables: { id, status } });
        if (!statusLoading) {
            setApproveSeller(false);
            setRejectSeller(false)
        }
    };

    const handleCancelClick = () => {
        setShowMap(false)
        setMapForTrack(false)
    }
    const handleOpenMap = () => {
        setShowMap(true)
    }

    const handleOpenMapForTrack = () => {
        setShowMap(true)
        setMapForTrack(true);
    }

    const handleImageModal = (item) => {
        setImageModal(true);
        setImage(item);
    }

    const handleDeleteImage = (index) => {
        const updatedImages = formData.oldPhotosUrls.filter((_, i) => i !== index);
        setFormData({ ...formData, oldPhotosUrls: updatedImages });
        setImagePreview(updatedImages);
    };

    const handlePrint = () => {
        window.print();
    };

    const lat = catchDetail?.catchInfo?.latitude;
    const lng = catchDetail?.catchInfo?.longitude;

    const handleEditAdminSideCatch = async (catchId) => {
        const parsedTotalWeight = parseFloat(formData.totalWeight);
        const parsedTotalPrice = parseFloat(formData.totalPrice);
        const parsedBuyerPrice = parseFloat(formData.totalBuyerPrice);

        const input = {
            totalWeight: parsedTotalWeight || null,
            totalBuyerPrice: parsedBuyerPrice || null,
            tagNumber: formData.tagNumber || null,
            story: formData.story || null,
            sellerPricePerLbs: formData.sellerPricePerLbs || null,
            buyerPricePerLbs: formData.buyerPricePerLbs || null,
            newPhotosFiles: formData.newPhotosFiles || null,
            oldPhotosUrls: imagePreview || null
        };

        try {
            const response = await editAdminSideCatch({
                variables: {
                    catchId,
                    input
                }
            });
            setDisableEdit(true);
            return response.data.editAdminSideCatch.message;
        } catch (e) {
            console.error('Error editing admin side catch:', e);
            ToastNotification({ message: e.message, type: 'error' });
        }
    };

    const handleDeleteCatch = () => {
        deleteCatch({ variables: { catchId: id } })
            .then(response => {
                if (response.data?.adminDeleteCatch?.message) {
                    console.log('Catch deleted successfully:', response.data.adminDeleteCatch.message);
                }
                navigate('/fish-inventory')
            })
            .catch(err => {
                console.error('Error deleting catch:', err);
            });
    }

    const handleDeletePopup = () => {
        setOpenDeletePopup(true);
    }

    
    useEffect(() => {
        if (mapForTrack && adminCatchesTrackInfo?.length > 0 && window.google && window.google.maps) {
          const bounds = new window.google.maps.LatLngBounds();
          adminCatchesTrackInfo?.forEach(info => {
            bounds.extend(new window.google.maps.LatLng(info?.latitude, info?.longitude));
          });
          const newZoomLevel = Math.min(15, 17); 
          const firstMarkerPosition = adminCatchesTrackInfo[0];
          const center = {
            lat: firstMarkerPosition?.latitude,
            lng: firstMarkerPosition?.longitude,
          };
          
          setMapCenter(center);
          setZoomLevel(newZoomLevel);
        } else {
          setMapCenter({ lat, lng });
          setZoomLevel(5);
        }
      }, [mapForTrack, adminCatchesTrackInfo]);
    


    return (
        <>
            {loading || error || !data ? (
                <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
                    {loading ? (
                        <InlineLoader />
                    ) : error ? (
                        <p className='error_text'>Error: {error.message}</p>
                    ) : (
                        <p className='error_text'>No data available</p>
                    )}
                </div>
            ) : (
                <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
                    <Toaster position="top-right" reverseOrder={false} />
                    {url !== "fish-details" && url !== "fish-inventory" && url !== "catch-details" && url !== 'fish-details-2' &&
                        <div className="flex items-center justify-end gap-5 mb-3 sm:mb-5">
                            <button
                                className="w-1/2 sm:w-[122px] h-10 bg-[#E8E9FF] text-base text-[#161941] rounded-lg sm:font-bold"
                                onClick={() => handleRejectSeller(true)}
                            >
                                Reject
                            </button>
                            <button
                                className="w-1/2 sm:w-[122px] h-10 bg-[#161941] text-base text-white rounded-lg sm:font-bold"
                                onClick={() => handleApproveSeller(true)}
                            >
                                Approve
                            </button>
                        </div>
                    }
                    <div className="flex gap-6 flex-wrap md:flex-wrap xl:flex-nowrap">
                        <div className="rounded-[10px] bg-white md:w-full lg:w-full xl:w-[calc(100%-400px)]">
                            <div className="grid grid-cols-1 lg:grid-cols-1 mb-6 rounded-md overflow-hidden">
                                <div className="relative">
                                    <div className="w-full">
                                        <div className="flex flex-wrap sm:flex-nowrap items-center px-0 justify-between sm:px-8 border-b border-[#e0d6d6] bg-white">
                                            <div className="flex border-b border-[#ededed] sm:border-none w-full sm:w-auto">
                                                <button
                                                    onClick={() => setOpenTab(1)}
                                                    className={`w-1/2 sm:w-auto relative font-medium text-base sm:text-xl py-5 sm:px-10 outline-none focus:shadow-outline-blue transition-all duration-300 ${openTab === 1 ? 'bg-transparent text-[#161941] dropline' : 'text-[#828282]'
                                                        }`}
                                                >
                                                    Catch Details
                                                </button>
                                                {url !== "details" && <button
                                                    onClick={() => setOpenTab(2)}
                                                    className={`w-1/2 sm:w-auto relative font-medium text-base sm:text-xl py-5 sm:px-10 outline-none focus:shadow-outline-blue transition-all duration-300 ${openTab === 2 ? 'bg-transparent text-[#161941] dropline' : 'text-[#828282]'
                                                        }`}
                                                >
                                                    Track Info
                                                </button>}
                                            </div>
                                            {url === "fish-inventory" && openTab === 1 ?
                                                <div className="flex gap-4">
                                                    {disableEdit && (
                                                        <>
                                                            <button className="w-8 sm:w-[47px] h-8 sm:h-[47px] flex items-center justify-center rounded-[5px] sm:rounded-[10px] border-[2px] border-[#CCCCCC]" onClick={() => setDisableEdit(!disableEdit)} >
                                                                <img className="w-4 sm:w-6" src="/images/edit_icon.svg" alt="Edit" />
                                                            </button>
                                                            <button className="w-8 sm:w-[47px] h-8 sm:h-[47px] flex items-center justify-center rounded-[5px] sm:rounded-[10px] border-[2px] border-[#B84D4D] " onClick={() => handleDeletePopup()}>
                                                                <img className="w-4 sm:w-6" src="/images/delete_icon_red.svg" alt="Delete" />
                                                            </button>
                                                        </>
                                                    )
                                                    }
                                                    {!disableEdit &&
                                                        <button className="bg-[#161941] flex items-center justify-center rounded-[10px] py-2 px-2 text-white text-sm font-semibold" onClick={() => handleEditAdminSideCatch(id)}>
                                                            <span>{editLoading ? <div className="upload_loader" style={{ marginLeft: "0%" }}></div> : "Save"}</span>
                                                        </button>}
                                                </div> :
                                                <div className="flex w-full sm:w-auto   gap-4">
                                                    {openTab === 2 &&
                                                        <button className="bg-[#161941] m-4 sm:m-0 w-full sm:w-auto flex items-center justify-center rounded-[10px] py-2 px-2 text-white text-sm font-semibold" onClick={handleOpenMapForTrack}>
                                                            <span>Details</span>
                                                        </button>}
                                                </div>
                                            }

                                        </div>
                                        {openTab === 1 && (
                                            <div className="transition-all duration-300 bg-white">
                                                <div className="w-full">
                                                    <div className="p-4 sm:p-9">
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-6 mb-4 sm:mb-6">
                                                            <div>
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Fish Species
                                                                </label>
                                                                {/* <Select
                                                                    className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'text-[#4B5563]'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full border border-[#e6e6e6]`}
                                                                    placeholder="Fish Species"
                                                                    name="fishSpecies"
                                                                    value={fishSpeciesOptions?.find(option => option.value === formData?.speciesId)}
                                                                    onChange={(selectedOption) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            speciesId: selectedOption?.value,
                                                                            fishSpecies: selectedOption?.label
                                                                        });
                                                                        setIsSpeciesDropdownOpen(false);
                                                                    }}
                                                                    options={fishSpeciesOptions}
                                                                    isDisabled={true}
                                                                    styles={{
                                                                        control: (provided, state) => ({
                                                                            ...provided,
                                                                            minHeight: "50px",
                                                                            backgroundColor: disableEdit ? '#F6F6F6' : 'white',
                                                                            fontSize: '0.875rem',
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                            outline: 'none',
                                                                            '&:hover': {
                                                                                border: 'none',
                                                                            },
                                                                           
                                                                        }),
                                                                        menu: (provided) => ({
                                                                            ...provided,
                                                                            zIndex: 9999,
                                                                            marginLeft: '-12px',
                                                                        }),
                                                                        placeholder: (provided) => ({
                                                                            ...provided,
                                                                            color: '#757575',
                                                                        }),
                                                                        singleValue: (provided) => ({
                                                                            ...provided,
                                                                            color: '#3b3939', // Text color when an option is selected
                                                                        }),
                                                                    }}
                                                                /> */}
                                                                <div className="relative">
                                                                    <input
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        type="text"
                                                                        placeholder="fishSpecies"
                                                                        name="fishSpecies"
                                                                        value={formData?.fishSpecies}
                                                                        disabled
                                                                        onChange={handleInputChange}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Weight
                                                                </label>
                                                                <div className="relative">
                                                                    <input
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        type="text"
                                                                        placeholder="Weight"
                                                                        name="totalWeight"
                                                                        value={formData?.totalWeight}
                                                                        disabled={disableEdit}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <span className="absolute top-1/2 -translate-y-1/2 right-4 text-base text-primary">lbs</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Seller Price/lb
                                                                </label>
                                                                <div className="relative">
                                                                    <input
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        type="text"
                                                                        placeholder="sellerPricePerLbs"
                                                                        name="sellerPricePerLbs"
                                                                        value={formData?.sellerPricePerLbs}
                                                                        disabled={disableEdit}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <span className="absolute top-1/2 -translate-y-1/2 right-4 text-base text-primary">lbs</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Tag Number
                                                                </label>
                                                                <input
                                                                    className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                    type="text"
                                                                    placeholder="AA 11 A 1234"
                                                                    name="tagNumber"
                                                                    value={formData?.tagNumber}
                                                                    disabled={disableEdit}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-span-1">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Date
                                                                </label>
                                                                <input
                                                                    className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                    type="text"
                                                                    placeholder="Date / Time"
                                                                    name="dateTime"
                                                                    value={moment(formData?.dateTime).format('MM/DD/YYYY')}
                                                                    disabled
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Buyer Price/lb
                                                                </label>
                                                                <div className="relative">
                                                                    <input
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        type="text"
                                                                        placeholder="buyerPricePerLbs"
                                                                        name="buyerPricePerLbs"
                                                                        value={formData?.buyerPricePerLbs}
                                                                        disabled={disableEdit}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <span className="absolute top-1/2 -translate-y-1/2 right-4 text-base text-primary">lbs</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 sm:col-span-2">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Location
                                                                </label>
                                                                <input
                                                                    className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                                    type="text"
                                                                    placeholder="Address"
                                                                    name=""
                                                                    id=""
                                                                    value={formData?.location}
                                                                    onClick={handleOpenMap}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-4 sm:mb-6">
                                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="address">Seller Price</label>
                                                                <div className="relative">
                                                                    <span className="absolute top-3 left-3 text-[#4B5563] text-sm sm:text-lg tracking-[1px]">$</span>
                                                                    <input
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-5 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        type="text"
                                                                        placeholder="totalPrice"
                                                                        name="totalPrice"
                                                                        value={typeof formData?.totalPrice === 'number' ? formData.totalPrice.toFixed(2) : '0.00'}
                                                                        disabled
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="address">Latitude </label>
                                                                <input
                                                                    className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                    type="text"
                                                                    placeholder="latitude"
                                                                    name="latitude"
                                                                    value={formData?.latitude}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="address">Longitude</label>
                                                                <input
                                                                    className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                    type="text"
                                                                    placeholder="longitude"
                                                                    name="longitude"
                                                                    value={formData?.longitude}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        {(!disableEdit || (storyData && storyData.trim() !== '')) &&
                                                            <div className="grid grid-cols-1 gap-4 sm:gap-6 mb-4 sm:mb-6">
                                                                <div className="col-span-1">
                                                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                        Story
                                                                    </label>
                                                                    <textarea
                                                                        className={`font-medium ${disableEdit ? 'bg-[#F6F6F6]' : 'bg-white'} text-sm sm:text-base ${disableEdit ? 'text-[#4B5563]' : 'bg-white'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full  border border-[#e6e6e6]`}
                                                                        name="story"
                                                                        value={formData?.story}
                                                                        disabled={disableEdit}
                                                                        onChange={handleInputChange}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="grid grid-cols-1 gap-4 sm:gap-6 mb-4 sm:mb-6">
                                                            <div className="col-span-1">
                                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                                    Photos
                                                                </label>
                                                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
                                                                    {formData?.oldPhotosUrls.map((image, index) =>
                                                                        <div className="w-full">
                                                                            {loading ?
                                                                                <div className='shimmer h-32 w-full object-cover'></div>
                                                                                : <div className='relative'>
                                                                                    <img
                                                                                        className="h-32 w-full object-cover"
                                                                                        src={image}
                                                                                        alt="Fish Image"
                                                                                        onClick={() => handleImageModal(image)}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                    />
                                                                                    {!disableEdit &&
                                                                                        <button
                                                                                            className="flex items-center justify-center rounded-[10px] absolute bottom-0 right-0 bg-white p-1"
                                                                                            onClick={() => handleDeleteImage(index)}
                                                                                            disabled={disableEdit}
                                                                                        >
                                                                                            <img className="w-6" src="/images/delete_icon_red.svg" alt="Delete" />
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    {!disableEdit &&
                                                                        <div className="w-full">
                                                                            <div className='relative'>
                                                                                <label htmlFor="image-upload" className="flex items-center justify-center h-32 w-full cursor-pointer bg-[#f1f1f1] rounded">
                                                                                    <img className="w-5 h-5" src="/images/icon.svg" alt="Add" />
                                                                                    <input
                                                                                        type="file"
                                                                                        accept="image/*"
                                                                                        id="image-upload"
                                                                                        className="hidden h-32 w-full"
                                                                                        onChange={(e) => handleImageChange(e)}
                                                                                        disabled={disableEdit}
                                                                                    />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {openTab === 2 && (
                                            <div className="transition-all duration-300 bg-white">
                                                <div className="bg-white h-[65vh] overflow-y-auto">
                                                    <div className="overflow-x-auto">
                                                        <table className="w-full min-w-[768px]">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                                                        Scanned By
                                                                    </th>
                                                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                                                        Date/Time
                                                                    </th>
                                                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                                                        Location
                                                                    </th>
                                                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                                                        Notes
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {trackLoading || trackError || adminCatchesTrackInfo?.length === 0 ? (
                                                                     <tr>
                                                                        <td colSpan={7} style={{ height: 450 }}>
                                                                            {trackLoading ? (
                                                                                <InlineLoader />
                                                                            ) : trackError ? (
                                                                                <p className='error_text'>Error: {trackError.message}</p>
                                                                            ) : (
                                                                                <p className='error_text' >
                                                                                    <img src="/images/empty_track.svg" alt=" " />
                                                                                </p>
                                                                            )}
                                                                        </td>
                                                                     </tr>
                                                                ) : 
                                                                adminCatchesTrackInfo && adminCatchesTrackInfo?.map((info) =>
                                                                    <tr>
                                                                        <td className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold" style={{ width: "190px" }}>
                                                                            {info?.firstName}  {info?.lastName}
                                                                        </td>
                                                                        <td className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold" style={{ width: "190px" }}>
                                                                            {moment(info?.createdAt).format('MM/DD/YYYY')}
                                                                        </td>
                                                                        <td className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[28px] font-semibold" style={{ width: "190px" }}>
                                                                            <div className="location-scroll whitespace-nowrap w-[150px] overflow-x-auto cursor-pointer">{info?.address}</div>
                                                                        </td>
                                                                        <td className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold" style={{ width: "190px" }}>
                                                                            {info?.statusNote}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ml-auto xl:ml-0 w-full sm:w-[400px]">
                            <div className="bg-white rounded-[10px] overflow-hidden">
                                <div className="w-full border-b border-[#EDEDED] justify-between items-center p-4 sm:py-6 sm:px-[18px] flex">
                                    <h3 className="font-medium text-lg sm:text-xl">Catch Summary</h3>
                                    <span onClick={handlePrint}><img src="/images/catch_summary_icon.svg" alt="Catch Summary Icon" /></span>
                                </div>
                                <div className="flex flex-wrap sm:flex-nowrap gap-4 sm:gap-6 p-4 pb-0 sm:pb-4 sm:pt-7 sm:px-[18px]">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24">
                                        {loading ? <div className='shimmer w-full h-full object-cover rounded-lg'></div> :
                                            <img className="w-full h-full object-contain rounded-lg" src={catchDetail?.fishSpeciesImage || "/images/blue_fish_icon.svg"} alt="Fish Image" onClick={(e) => handleImageModal(catchDetail?.fishSpeciesImage)} />
                                        }
                                    </div>
                                    <div className="w-full gap-y-5 sm:gap-y-0 sm:w-[calc(100%-114px)] flex flex-wrap">
                                        <span className="w-full text-lg sm:text-xl font-medium text-black">{catchDetail?.catchInfo?.fishSpecies}</span>
                                        <div className="w-full flex justify-between items-end">
                                            <div className="flex gap-4 items-center">
                                                <span className="w-6 h-6"><img src="/images/blue_fish_icon.svg" alt="Blue Fish Icon" /></span>
                                                <span><img src="/images/times_icon.svg" alt="Times Icon" /></span>
                                                <span className="text-2xl text-black">1</span>
                                            </div>
                                            <span className="flex items-center text-lg sm:text-lg text-black"><img className="w-5" src="/images/total_icon.svg" alt="Total Icon" /> {catchDetail?.catchInfo?.weight + "lbs"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 pb-6 mt-1 sm:mt-0">
                                    <label
                                        className="text-sm mb-1 font-semibold block tracking-[1px] text-[#757575]"
                                        htmlFor="password"
                                    >
                                        Buyer Price
                                    </label>
                                    <div className="relative">
                                        <span className="absolute top-1/2 left-4  text-[#757575] text-lg tracking-[1px]" style={{ margin: "-15px 3px" }}>$</span>
                                        <input
                                            className="border-none placeholder:text-'#4B5563' font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-8 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            name="totalBuyerPrice"
                                            value={typeof formData.totalBuyerPrice === 'number' ? formData.totalBuyerPrice.toFixed(2) : '0.00'}
                                            onChange={handleInputChange}
                                            disabled={disableEdit}
                                        />
                                        {/* <button
                                            type="button"
                                            className="text-[#161941] text-sm font-bold absolute top-1/2 -translate-y-1/2 right-3"
                                        >
                                            Change
                                        </button> */}
                                    </div>
                                </div>
                                {/* <div className="mt-3 sm:mt-24 flex justify-between items-center p-4 sm:py-4 sm:px-7 bg-[#DBDEFF]">
                                    <span className="text-[#161941] text-base sm:text-xl font-medium">Total Price</span>
                                    <span className="text-[#161941] text-base sm:text-xl font-medium"> {"$" + (parseFloat(catchDetail?.catchInfo?.totalPrice) || 0).toFixed(2)}</span>
                                </div> */}
                            </div>
                            {url === "fish-details" ?
                                <div className="w-full mt-3" onClick={() => getPaymentStatus()}>
                                    <button className={`w-full ${paymentStatus !== "Pending" ? "bg-[#E0E0E0]" : "bg-[#161941]"} text-base sm:font-bold tracking-[1px] text-white rounded-[10px] h-[45px] sm:h-12`}>
                                        {paymentLoading ? <div className="upload_loader"></div> : paymentStatus !== "Pending" ? "Paid!" : "Complete Payment"}
                                    </button>
                                </div>
                                : url === "fish-inventory" &&
                                <div className="w-full mt-3" onClick={() => getPaymentStatus()}>
                                    <button className={`w-full ${paymentStatus !== "Pending" ? "bg-[#E0E0E0]" : "bg-[#161941]"} text-base sm:font-bold tracking-[1px] text-white rounded-[10px] h-[45px] sm:h-12`}>{paymentStatus !== "Pending" ? "Paid!" : "Complete Payment"}</button>
                                </div>
                            }
                            <div className="mt-7 bg-white rounded-[10px] overflow-hidden">
                                <div className="w-full border-b border-[#EDEDED] justify-between items-center p-4 sm:py-6 sm:px-[18px] flex">
                                    <h3 className="font-medium text-lg sm:text-xl">Seller’s Info</h3>
                                </div>
                                <div className="flex sm:px-[18px] p-4 sm:py-7 gap-5">
                                    <div className="w-20 h-20 sm:w-24 sm:h-24">
                                        {loading ? <div className='shimmer w-full h-full rounded-[10px] object-cover'></div> :
                                            <img className="w-full h-full rounded-[10px] object-cover" src={catchDetail?.sellerInfo?.image} alt="Profile Image" onClick={(e) => handleImageModal(catchDetail?.sellerInfo?.image)} />
                                        }
                                    </div>
                                    <div className="w-[calc(100%-90px)]">
                                        <span className="text-xl font-medium text-black">{catchDetail?.sellerInfo?.name} </span>
                                        <a href="" className="tetx-sm font-medium text-[#4B5563] block my-2">{catchDetail?.sellerInfo?.email}</a>
                                        <a href="" className="tetx-sm font-medium text-[#4B5563]">{catchDetail?.sellerInfo?.phoneNumber?.dialCode}{catchDetail?.sellerInfo?.phoneNumber?.number}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {approveSeller ?
                        <div class="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
                            <div
                                class="w-[340px] px-6 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center"
                            >
                                <span class="w-full">
                                    <img
                                        class="mx-auto w-auto h-auto"
                                        src="images/approve_catch_icon.svg"
                                        alt=""
                                    /></span>
                                <h3 class="text-lg font-bold py-3">Approve this catch ?</h3>
                                <p class="text-sm">Are you sure you want to approve this catch?</p>
                                <div class="mt-3 flex gap-3 justify-center">
                                    <button
                                        class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold"
                                        onClick={() => handleApproveSeller(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"
                                        onClick={() => handleChangeStatus("approved")}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div> : ""}
                    {rejectSeller ?
                        <div class="fixed top-0 left-0 w-full h-full bg-black/50 z-50 ">
                            <div
                                class="w-[340px] px-6 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center"
                            >
                                <span class="w-full">
                                    <img
                                        class="mx-auto w-auto h-auto"
                                        src="images/approve_catch_icon.svg"
                                        alt=""
                                    /></span>
                                <h3 class="text-lg font-bold py-3">Reject this catch?</h3>
                                <p class="text-sm">Are you sure you want to reject this catch?</p>
                                <div class="mt-3 flex gap-3 justify-center">
                                    <button
                                        class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold"
                                        onClick={() => handleRejectSeller(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"
                                        onClick={() => handleChangeStatus("rejected")}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div> : ""}
                    {showMap && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                            <div className="relative w-[90%] h-[80%] bg-white rounded-md p-10">
                                <button
                                    className="absolute top-2 right-2 text-black"
                                    onClick={handleCancelClick}>
                                    <img src='/images/times_icon.svg' alt="Close" />
                                </button>
                                <LoadScriptNext googleMapsApiKey="AIzaSyBWF8V2GDtVE7epKyyXZV7HFmDBfEVLscY">
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        center={mapCenter}
                                        zoom={zoomLevel}
                                        options={{ mapTypeId: "satellite", scrollwheel: false }}>
                                        {!mapForTrack &&
                                            <MarkerF position={{ lat, lng }} icon={fishIcon} />}
                                        {mapForTrack && adminCatchesTrackInfo &&
                                            adminCatchesTrackInfo?.map((info, index) => (
                                                <MarkerF
                                                    key={index}
                                                    position={{ lat: info.latitude, lng: info.longitude }}
                                                    icon={fishIcon}
                                                    onMouseOver={() => setHoveredMarker({ lat: info.latitude, lng: info.longitude, info })}
                                                    onMouseOut={() => setHoveredMarker(null)}
                                                >
                                                    {hoveredMarker &&
                                                        hoveredMarker.lat === info.latitude &&
                                                        hoveredMarker.lng === info.longitude && (
                                                            <InfoWindowF 
                                                                position={{ lat: info.latitude, lng: info.longitude }}
                                                                options={{ disableAutoPan: true }}
                                                                >
                                                                <div>
                                                                    <p>Date: {moment(info.createdAt).format("MM/DD/YYYY")}</p>
                                                                    <p>Time: {moment(info.createdAt).format("HH:mm")}</p>
                                                                    <p>Scanner User: {info.address}</p>
                                                                </div>
                                                            </InfoWindowF>
                                                        )}
                                                </MarkerF>
                                            ))}
                                    </GoogleMap>
                                </LoadScriptNext>
                            </div>
                        </div>
                    )}
                    {imageModal &&
                        <ImageModal image={image} setImageModal={setImageModal} />
                    }
                    {openDeletePopup ?
                        <div class="fixed top-0 left-0  w-full h-full bg-black/50 z-50 ">
                            <div class="w-[340px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2  pt-5 pb-9 bg-white  rounded-[10px] text-center">
                                <span class="w-full"> <img class="mx-auto w-auto h-auto" src="/images/delete_icon_red.svg" alt="" /></span>
                                <h3 class="text-lg font-bold py-3">Please Confirm!</h3>
                                <p class="text-sm ">Are you sure you want to delete</p>
                                <div class="mt-6 flex gap-3 justify-center">
                                    <button class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold" onClick={() => setOpenDeletePopup(false)}>Cancel</button>
                                    <button class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold" onClick={handleDeleteCatch}>Delete</button>
                                </div>
                            </div>
                        </div> : ""
                    }
                </div>

            )}
        </>
    );
};

export default CatchDetailPage;
