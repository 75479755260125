import React, { useState } from 'react';
import { FORGOT_PASSWORD } from '../queries/AuthQuery';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../utils/ToastNotification';

const ForgotPassword = () => {
  const [forgot, { data, loading, error }] = useMutation(FORGOT_PASSWORD);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  localStorage.setItem('forgotEmail', email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      ToastNotification({ message: 'Please enter your email address.', type: 'error' });
      return;
    }

    try {
      const { data } = await forgot({
        variables: { email }
      });
      if (data.forgotPassword.message) {
        setTimeout(() => {
          navigate('/verify-mail');
        }, 1000); 
      }
    } catch (err) {
      ToastNotification({ message: err.message, type: 'error' });
    }
  };

  return (
    <div className="bg-white flex items-center h-screen justify-center">
        <Toaster position="top-right" reverseOrder={false} />
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[110px]" onClick={()=>navigate(-1)}>
          <button className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border" >
            <img src="/images/back_arrow_icon.svg" alt="" />
          </button>
        </div>
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <a href="#" className="static sm:fixed top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img className="mx-auto sm:mx-0 w-[110px] xl:w-[120px]" src="/images/logo_new.png" alt=""/>
          </a>  
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px] text-[#4F4F4F]">Forgot Password?</h2>
            <p className="text-[#4F4F4F] text-[16px]">Please enter your registered email id</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-full relative mb-[10px]">
              <input 
                className="text-sm sm:text-sm md:text-base w-full border-2 border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[70px]"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="absolute -translate-y-1/2 top-1/2 left-5">
                <img className="w-[22px] xl:w-auto" src="/images/mail_icon.svg" alt="" />
              </span>
            </div>
            <div className="w-full mt-[15px] xl:mt-[34px]">
              <button type="submit" className="w-full rounded-[10px] font-bold bg-[#161941] text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px] xl:h-[70px]"> {loading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Send OTP"
                    )}</button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src="/images/new-image.png" alt="" />
      </div>
    </div>
  );
};

export default ForgotPassword;
