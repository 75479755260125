import { Link, useNavigate, useParams } from "react-router-dom";
import { CHANGE_STATUS, GET_SELLER_REQUEST_BY_ID } from "../../queries/SellerQuery";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InlineLoader from "../utils/InlineLoader";
import { Toaster } from 'react-hot-toast';
import ToastNotification from "../../utils/ToastNotification";
import { useSelector } from "react-redux";


const SellerRequestDetailPage = () => {
    const { id } = useParams();
    const [status, setStatus] = useState();
    const navigate = useNavigate();
    const { loading, error, data, refetch } = useQuery(GET_SELLER_REQUEST_BY_ID, {
        variables: { id },
    });
    const { currentPage } = useSelector((state)=> state.auth);
    const { loading: statusLoading, error: statusError, data: statusData } = useQuery(CHANGE_STATUS, {
        variables: { id, status },
        onCompleted: (data) => {
            ToastNotification({ message: 'Status changed successfully', type: 'success' });
            setTimeout(() => {
                navigate(`/seller-request/${currentPage}`);
            }, 1000);
        },
    });

    useEffect(() => {
        refetch();
    }, [id, refetch]);

    const { getSellerRequestDetail } = data || {};

    const [rejectSeller, setRejectSeller] = useState(false);
    const [approveSeller, setApproveSeller] = useState(false);

    const handleApproveSeller = (value) => {
        setApproveSeller(value);
    }

    const handleRejectSeller = (value) => {
        setRejectSeller(value);
    }

    const handleChangeStatus = async (status) => {
        setStatus(status);
        if (!statusLoading) {
            setApproveSeller(false);
            setRejectSeller(false)
        }
    };

    return (
        <>
            {loading || error || !data ? (
                <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
                    {loading ? (
                        <InlineLoader />
                    ) : error ? (
                        <p className='error_text'>Error: {error.message}</p>
                    ) : (
                        <p className='error_text'>No data available</p>
                    )}
                </div>
            ) : (
                <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
                    <Toaster position="top-right" reverseOrder={false} />
                    <div class="flex items-center justify-end gap-5 mb-3 sm:mb-5">
                        <button
                            class="w-1/2 sm:w-[122px] h-[40px] sm:h-12 bg-[#E8E9FF] text-base text-[#161941] rounded-lg font-semibold sm:font-bold"
                            onClick={() => handleRejectSeller(true)}
                        >
                            Reject
                        </button>
                        <button
                            class="w-1/2 sm:w-[122px] h-[40px] sm:h-12 bg-[#161941] text-base text-white rounded-lg font-semibold sm:font-bold"
                            onClick={() => handleApproveSeller(true)}
                        >
                            Approve
                        </button>
                    </div>
                    <div className="bg-white px-4 lg:px-9 py-4 lg:py-7">
                        <div className="mb-3 sm:mb-5 xl:mb-11">
                            <h3 className="text-lg font-semibold lg:text-[22px] text-[#727272]">
                                Personal Information
                            </h3>
                        </div>
                        <div className="flex w-full flex-wrap xl:flex-nowrap items-start gap-4">
                            <div className="w-20 h-20 sm:w-28 sm:h-28">
                                {loading ?
                                    <div className='shimmer w-full h-full object-cover rounded-md'></div>
                                    : <img
                                        className="w-full h-full object-cover rounded-md"
                                        src={getSellerRequestDetail?.personalInfo?.image}
                                        alt=""
                                    />}
                            </div>
                            <div className="lg:w-full xl:w-[calc(100%-114px)]">
                                <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-2 xl:grid-cols-4 gap-6 mb-6">
                                    <div>
                                        <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                            Name
                                        </label>
                                        <input
                                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="Henry Estward"
                                            name=""
                                            id=""
                                            value={getSellerRequestDetail?.personalInfo?.name}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                            Email
                                        </label>
                                        <input
                                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="email"
                                            placeholder="abcde@gmail.com"
                                            name=""
                                            id=""
                                            value={getSellerRequestDetail?.personalInfo?.email}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                            Mobile Number
                                        </label>
                                        <input
                                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="+91 1234567890"
                                            name=""
                                            id=""
                                            value={`${getSellerRequestDetail?.personalInfo?.phoneNumber?.dialCode}${getSellerRequestDetail?.personalInfo?.phoneNumber?.number}`}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-6 mb-6">
                                    <div>
                                        <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                            Address
                                        </label>
                                        <input
                                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="Address"
                                            name=""
                                            id=""
                                            value={getSellerRequestDetail?.personalInfo?.address}
                                        />
                                    </div>
                                    <div>
                                        <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                            Bio
                                        </label>
                                        <textarea
                                            className="border-none placeholder:text-[#4B5563] text-left font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] pt-3 px-3 h-[80px] sm:h-[115px] rounded-[10px] w-full"
                                            name=""
                                            id=""
                                            placeholder="At vero eos et accusamus..."
                                            value={getSellerRequestDetail?.personalInfo?.bio}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white px-4 sm:px-9 py-4 lg:py-7 mt-5 lg:mt-10 mb-12">
                        <div className="mb-4 sm:mb-5 xl:mb-11">
                            <h3 className="text-lg font-semibold lg:text-[22px] text-[#727272]">
                                Company Information
                            </h3>
                        </div>
                        <div className="flex w-full flex-wrap xl:flex-nowrap items-start gap-4">
                            {getSellerRequestDetail?.companyInfo?.logo &&
                                <div className="w-20 h-20 sm:w-28 sm:h-28">
                                    {loading ?
                                        <div className='shimmer w-full h-full object-cover rounded-md'></div>
                                        : <img
                                            className="w-full h-full object-cover rounded-md"
                                            src={getSellerRequestDetail?.companyInfo?.logo}
                                            alt=""
                                        />}
                                </div>}
                            <div className="w-full xl:w-[calc(100%-114px)]">
                                <div className="flex flex-wrap md:justify-between lg:justify-normal gap-4 mb-4 sm:mb-6">
                                    {getSellerRequestDetail?.companyInfo?.name &&
                                        <div className="w-full md:w-[48%] lg:w-1/4">
                                            <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                Company Name
                                            </label>
                                            <input
                                                className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                type="text"
                                                placeholder="TACO BELLS"
                                                name=""
                                                id=""
                                                value={getSellerRequestDetail?.companyInfo?.name}
                                            />
                                        </div>
                                    }
                                    {getSellerRequestDetail?.companyInfo?.website &&
                                        <div className="w-full md:w-[48%] lg:w-1/4">
                                            <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                Company’s Website
                                            </label>
                                            <input
                                                className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                type="text"
                                                placeholder="tacobells.co.in"
                                                name=""
                                                id=""
                                                value={getSellerRequestDetail?.companyInfo?.website}
                                            />
                                        </div>
                                    } {getSellerRequestDetail?.companyInfo?.species?.length > 0 &&
                                        <div className="w-auto">
                                            <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                Target Species Landed
                                            </label>
                                            <div className="flex flex-wrap items-center  gap-3">
                                                {getSellerRequestDetail?.companyInfo?.species.map((specie) =>
                                                    <button className="py-3 px-5 rounded-md bg-[#3D4289] text-xs font-bold tracking-[1px] text-white">
                                                        {specie}
                                                    </button>
                                                )}
                                            </div>
                                        </div>}
                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-6">
                                        {getSellerRequestDetail?.companyInfo?.state &&
                                            <div>
                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                    State License
                                                </label>
                                                <input
                                                    className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                    type="text"
                                                    placeholder="State Name"
                                                    name=""
                                                    id=""
                                                    value={getSellerRequestDetail?.companyInfo?.state}
                                                />
                                            </div>
                                        }
                                        {getSellerRequestDetail?.companyInfo?.stateLicenseNumber &&
                                            <div>
                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                    License Number
                                                </label>
                                                <input
                                                    className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                    type="text"
                                                    placeholder="License Number"
                                                    name=""
                                                    id=""
                                                    value={getSellerRequestDetail?.companyInfo?.stateLicenseNumber}

                                                />
                                            </div>}
                                        {getSellerRequestDetail?.companyInfo?.federalLicenseNumber &&
                                            <div>
                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                    Federal License
                                                </label>
                                                <input
                                                    className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                    type="text"
                                                    placeholder="License Number"
                                                    name=""
                                                    id=""
                                                    value={getSellerRequestDetail?.companyInfo?.federalLicenseNumber}
                                                />
                                            </div>
                                        }
                                        {getSellerRequestDetail?.companyInfo?.ein &&
                                            <div>
                                                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                                                    EIN or SS#
                                                </label>
                                                <input
                                                    className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                                    type="text"
                                                    placeholder="AA 11 A 1234"
                                                    name=""
                                                    id=""
                                                    value={getSellerRequestDetail?.companyInfo?.ein}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {approveSeller ?
                            <div class="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
                                <div
                                    class="w-[340px] px-6 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center"
                                >
                                    <span class="w-full">
                                        <img
                                            class="mx-auto w-auto h-auto"
                                            src="images/approve_seller_icon.png"
                                            alt=""
                                        /></span>
                                    <h3 class="text-lg font-bold py-3">Approve this Seller ?</h3>
                                    <p class="text-sm">Are you sure you want to approve this request?</p>
                                    <div class="mt-3 flex gap-3 justify-center">
                                        <button
                                            class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold"
                                            onClick={() => handleApproveSeller(false)}
                                        >
                                            No
                                        </button>
                                        <button
                                            class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"
                                            onClick={() => handleChangeStatus("approved")}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div> : ''}
                        {rejectSeller ?
                            <div class="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
                                <div
                                    class="w-[340px] px-6 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center"
                                >
                                    <span class="w-full">
                                        <img
                                            class="mx-auto w-auto h-auto"
                                            src="images/approve_seller_icon.png"
                                            alt=""
                                        /></span>
                                    <h3 class="text-lg font-bold py-3">Reject this Seller?</h3>
                                    <p class="text-sm">Are you sure you want to reject this request?</p>
                                    <div class="mt-3 flex gap-3 justify-center">
                                        <button
                                            class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold"
                                            onClick={() => handleRejectSeller(false)}
                                        >
                                            No
                                        </button>
                                        <button
                                            class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"
                                            onClick={() => handleChangeStatus("rejected")}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div> : ''}
                    </div>
                </div>
                )}
                </>
            );
};

export default SellerRequestDetailPage;
