import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
   mutation AdminLoginResponseogin($email: String!, $password: String!) {
    AdminLoginResponseogin(input: { email: $email, password: $password }) {
      firstName
      lastName
      email
      image
      role
      createdAt
      updatedAt
      token
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
mutation ResetPassword($email: String!, $newPassword: String!, $confirmPassword: String!) {
  resetPassword(input: { email: $email, newPassword: $newPassword, confirmPassword: $confirmPassword }) {
    message
  }
}
`;

export const VERIFY_OTP = gql`
mutation VerifyOtpForResetPassword($email: String!, $otp: String!) {
  verifyOtpForResetPassword(input: { email: $email, otp: $otp }) {
      message
  }
}
`

export const CHANGE_PASSWORD_MUTATION = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!, $confirmPassword: String!) {
         changePassword(input: {
          currentPassword: $currentPassword
          newPassword: $newPassword
          confirmPassword: $confirmPassword
        }) {
            message
        }
    }
`;

export const EDIT_PROFILE_MUTATION = gql`
  mutation EditProfile( $firstName: String!, $lastName: String!, $oldProfileImageUrl: String!, $newProfileImageFile: Upload) {
    editProfile(input:{firstName:$firstName, lastName:$lastName, oldProfileImageUrl:$oldProfileImageUrl, newProfileImageFile:$newProfileImageFile}) {
      message
    }
  }
`;

export const ADMIN_QUERY = gql`
  query Admin($timeZone: String!) {
    admin(timeZone: $timeZone) {
      id
      firstName
      lastName
      email
      image
      createdAt
      updatedAt
      token
      timeZone
    }
  }
`;