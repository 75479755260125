import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADMIN_QUERY,
  CHANGE_PASSWORD_MUTATION,
  EDIT_PROFILE_MUTATION,
} from "../../queries/AuthQuery";
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';

const Settings = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    email: "",
    lastName: "",
    oldProfileImageUrl: "",
    newProfileImageFile: ""
  });

  const [changePasswordInput, setChangePasswordInput] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [imagePreview, setImagePreview] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState('');

  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION,{
    variables: {
      currentPassword: changePasswordInput.currentPassword,
      newPassword: changePasswordInput.newPassword,
      confirmPassword:changePasswordInput.confirmPassword
    },
    onCompleted: (data) => {
      ToastNotification({ message: "Password updated succesfully", type: 'success' });
      setIsModalVisible(false)
      setChangePasswordInput({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      refetch();
    },
    onError: (error) => {
      ToastNotification({ message: error.message, type: 'error' });
    }
  });

  const [editProfile, { data, error, loading }] = useMutation(EDIT_PROFILE_MUTATION, {
    onCompleted: (data) => {
      ToastNotification({ message: data?.editProfile?.message, type: 'success' });
      refetch();
    },
    onError: (error) => {
      ToastNotification({ message: error.message, type: 'error' });
    }
  }
  );

  const { loading: queryLoading, error: queryError, data: userData, refetch } = useQuery(ADMIN_QUERY, {
    variables: { timeZone: userTimeZone },
    onCompleted: (data) => {
      const adminData = {
        ...data?.admin,
        image: data?.admin?.image,
      };
      localStorage.removeItem("admin");
      localStorage.setItem('admin', JSON.stringify(adminData));
      setImagePreview(adminData?.image);
    },
  });

  const { admin } = userData || {}

  useEffect(() => {
    if (admin) {
      setFormValues({
        firstName: admin?.firstName || "",
        lastName: admin?.lastName || "",
        email: admin?.email || "",
        oldProfileImageUrl: admin?.image || '',
        newProfileImageFile: '',
      });
    }
    setImagePreview(admin?.image);
  }, [admin]);

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimeZone(timeZone);
  }, []);

  useEffect(() => {
    if (userTimeZone) {
      refetch({ timeZone: userTimeZone });
    }
  }, [userTimeZone, refetch]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleChangePasswordSubmit = (event) => {
    event.preventDefault(); 
    changePassword();
  };

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    setChangePasswordInput((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };



  const handleEditProfile = async (event) => {
    event.preventDefault();
    try {
      const { data } = await editProfile({
        variables: {
          firstName: formValues.firstName || "",
          lastName: formValues.lastName || "",
          email: formValues.email || "",
          oldProfileImageUrl: admin.image || "",
          newProfileImageFile: formValues.newProfileImageFile || null
        },
      });
    } catch (error) {
      console.error("Error editing profile:", error);
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const enableEditing = (event) => {
    event.preventDefault();
    setButtonEnable(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormValues(prevState => ({ ...prevState, newProfileImageFile: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <Toaster position="top-right" reverseOrder={false} />
      <form >
        <div className="w-full rounded-[10px] overflow-hidden">
          <div className="w-full p-4 sm:p-7 flex-wrap md:flex-nowrap gap-y-4 xl:pr-14 flex bg-white justify-between items-center">
            <div className="w-auto">
              <span className="font-semibold text-xl text-black">
                Basic Details
              </span>
              <p className="text-sm text-[#6B7280] tracking-[1px] mt-1 sm:mt-2">
                View & manage your basic profile details for the admin account
              </p>
            </div>
            <div className="flex items-center w-full sm:w-auto justify-between sm:justify-normal gap-5">
             {buttonEnable ?
              <button
                type="submit"
                className="h-[40px] sm:h-auto sm:py-2 px-6 rounded-md text-white bg-[#161941] text-base font-normal sm:font-bold"
                onClick={handleEditProfile}
              >
                {loading ? <div className="upload_loader" style={{marginLeft:"0%"}}></div> : "Save"}
              </button> :
              <button className="w-10 h-10 sm:w-[80px] sm:h-[40px] flex items-center justify-center rounded-[10px] border-[2px] border-[#CCCCCC]" onClick={enableEditing}>
              <img className="w-5 sm:w-auto" src="images/setting_edit_icon.svg" alt="" />
              <p style={{fontWeight:"800"}}>Edit</p>
              </button> 
              }
            </div>
          </div>
          <div className="w-full bg-white mb-10 rounded-b-lg">
            <div className="flex px-4 sm:px-8 w-full mb-7 gap-9">
              <div className="w-full border-b pb-4 xl:pb-7 border-[#D3D6FF]">
                <label
                  className="text-[#6B7280] mb-3 block text-sm tracking-[1px] font-semibold"
                  htmlFor="profile-picture"
                >
                  Your Profile Picture
                </label>
                <div className="relative w-24 h-24 md:w-32 md:h-32  lg:w-36 lg:h-36 border-dotted border-[2px] border-[#161941] rounded-sm">
                  <input
                    type="file"
                    className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
                    onChange={handleFileChange}
                    disabled ={ !buttonEnable }
                  />
                  {imagePreview ? (
                    <img className="w-full h-full object-cover rounded-md" src={imagePreview} alt="Fish" />
                  ) : (
                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <img src="images/placeholder_img.svg" alt="Placeholder" />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full px-4 sm:px-8 pb-24 sm:pb-40 xl:pb-52">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-6">
                <div>
                  <label
                    className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={`border-none placeholder:text-[${buttonEnable ? '#4B5563' : '#F6F6F6'}] font-medium bg-[#F6F6F6] text-sm sm:text-base ${buttonEnable ? 'text-[#4B5563]' : 'text-gray-400'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full`}
                    type="text"
                    placeholder="Henry"
                    id="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                    disabled={ !buttonEnable }
                  />
                </div>
                <div>
                  <label
                    className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    className={`border-none placeholder:text-[${buttonEnable ? '#4B5563' : '#F6F6F6'}] font-medium bg-[#F6F6F6] text-sm sm:text-base ${buttonEnable ? 'text-[#4B5563]' : 'text-gray-400'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full`}
                    type="text"
                    placeholder="Doe"
                    id="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                    disabled={!buttonEnable}
                  />
                </div>
                <div>
                  <label
                    className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm  sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                    type="email"
                    placeholder="abcde@gmail.com"
                    id="email"
                    value={formValues.email}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <label
                    className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      className={`border-none placeholder:text-[${buttonEnable ? '#4B5563' : '#F6F6F6'}] font-medium bg-[#F6F6F6] text-sm sm:text-base ${buttonEnable ? 'text-[#4B5563]' : 'text-gray-400'} px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full`}
                      type="password"
                      placeholder="xxxxxxxxxxxxx"
                      id="password"
                      readOnly
                      value={formValues.password}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      className="text-[#161941] text-sm font-bold absolute top-1/2 -translate-y-1/2 right-3"
                      onClick={toggleModal}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isModalVisible && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
          <div className="w-[90%] sm:w-[378px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 sm:p-[30px] bg-white rounded-[10px] text-center">
            <div className="mb-11 flex justify-between items-center">
              <h3 className="text-xl font-medium text-black">
                Change Password
              </h3>
              <button onClick={toggleModal}>
                <img src="images/times_icon.svg" alt="" />
              </button>
            </div>
            <form onSubmit={handleChangePasswordSubmit}>
              <div className="w-full">
                <div className="w-full">
                  <label
                    className="text-sm text-left font-medium text-[#4F4F4F] block mb-2"
                    htmlFor="currentPassword"
                  >
                    Current Password
                  </label>
                  <div className="relative w-full">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                      placeholder="**********"
                      id="currentPassword"
                      value={changePasswordInput.currentPassword}
                      onChange={handlePasswordChange}
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    >
                      <img src={showCurrentPassword ? "/images/eye_icon.svg" : "/images/hide-password.svg"} alt="" />
                    </button>
                  </div>
                </div>
                <div className="w-full my-[30px]">
                  <label
                    className="text-sm text-left font-medium text-[#4F4F4F] block mb-2"
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <div className="relative w-full">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                      placeholder="**********"
                      id="newPassword"
                      value={changePasswordInput.newPassword}
                      onChange={handlePasswordChange}
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      <img src={showNewPassword ? "/images/eye_icon.svg" : "/images/hide-password.svg"} alt="" />
                    </button>
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-sm text-left font-medium text-[#4F4F4F] block mb-2"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <div className="relative w-full">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                      placeholder="**********"
                      id="confirmPassword"
                      value={changePasswordInput.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      <img src={showConfirmPassword ? "/images/eye_icon.svg" : "/images/hide-password.svg"} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 mt-[34px] justify-between">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="w-[89px] h-[48px] rounded-[10px] text-[#161941] bg-[#ECEDFF] text-base font-bold"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-[89px] h-[48px] rounded-[10px] text-white bg-[#161941] text-base font-bold"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
