import React, { useEffect, useState } from 'react';
import { COMPLETE_ORDER_STATUS_QUERY, GET_ADMIN_BUYER_ORDER_DETAIL } from '../../queries/OrderQuery';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ToastNotification from '../../utils/ToastNotification';
import { Toaster } from 'react-hot-toast';
import InlineLoader from '../utils/InlineLoader';
import moment from 'moment';
import ImageModal from '../utils/ImageModal';
import { useSelector } from 'react-redux';


const OrderInfoPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const url = urlParts[urlParts.length - 2];
  const [imageModal, setImageModal] = useState(false);
  const { currentPage } = useSelector((state)=> state.auth);
  const [image, setImage] = useState('');
  
  const { loading, error, data, refetch } = useQuery(GET_ADMIN_BUYER_ORDER_DETAIL, {
    variables: { id: id, status: "completed" },
     fetchPolicy: "network-only" 
  });

  const [handlePaymentStatus,{ loading: statusLoading, error: errorLoading, data: statusData }]  = useLazyQuery(COMPLETE_ORDER_STATUS_QUERY, {
    variables: { id: id, status: "completed" },
    onCompleted: (data) => {
      ToastNotification({ message: 'Payment status updated successfully', type: 'success' });
        setTimeout(() => {
          navigate(`/orders/${currentPage}`);
        }, 1000);
    },
    onError: (err) => {
      ToastNotification({ message: err.message, type: 'error' });
    }
  });

  const adminBuyerOrderDetail = data?.adminBuyerOrderDetail || {};

  const handleImageModal = (item) =>{
    setImageModal(true);
    setImage(item);
  }

  useEffect(()=>{
    refetch();
  },[refetch,id])

  const handlePrint = () => {
    window.print();
  };
  
  
  return (
    <>
      {loading || error || !data ? (
        <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
          {loading ? (
            <InlineLoader />
          ) : error ? (
            <p className='error_text'>Error: {error.message}</p>
          ) : (
            <p className='error_text'>No data available</p>
          )}
        </div>
      ) : (
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
          <Toaster position="top-right" reverseOrder={false} />
          <div className="flex gap-6 flex-wrap xl:flex-nowrap">
            <div className="overflow-hidden md:w-full lg:w-full xl:w-[calc(100%-400px)]">
              <div className="bg-white rounded-[10px]">
                <div className="p-4 sm:py-6 sm:px-9 border-b border-[#EDEDED]">
                  <h3 className="font-medium text-lg sm:text-xl">Order Info</h3>
                </div>
                <div className="p-4 sm:p-9">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
                    <div>
                      <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="order-id">Order ID</label>
                      <input className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full" type="text" placeholder="#12345" id="order-id" value={adminBuyerOrderDetail?.orderId} />
                    </div>
                    <div>
                      <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="buyer-name">Buyer’s Name</label>
                      <input className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full" type="text" placeholder="Henry Estward" id="buyer-name" value={adminBuyerOrderDetail?.buyerName} />
                    </div>
                    <div>
                      <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="price-paid">Price Paid</label>
                      <input className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full" type="text" placeholder="" id="price-paid" value={`$${(parseFloat(adminBuyerOrderDetail?.totalAmount) || 0).toFixed(2)}`} />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <div className="col-span-2">
                      <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="address">Address</label>
                      <input className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full" type="text" placeholder="Address" id="address" value={adminBuyerOrderDetail?.address} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white mt-5 xl:my-11 rounded-[10px]">
                <div className="p-4 sm:py-6 sm:px-9 border-b border-[#EDEDED]">
                  <h3 className="font-medium text-lg sm:text-xl">Order Items</h3>
                </div>
                <div className="p-4 sm:p-9">
                  {adminBuyerOrderDetail?.items?.map((item) =>
                    <div className="flex flex-wrap sm:flex-nowrap pb-4 border-b border-[#D4D4D4] mb-5 justify-between items-start gap-4 sm:gap-6">
                      <div className="w-28 h-24 sm:w-40 sm:h-36 border border-[#E0E0E0] flex items-center justify-center">
                        {loading?  
                        <div className='shimmer w-full'></div>
                        :
                        <img className="w-full" src={item?.image} alt="Blue Marlin" onClick={(e)=>handleImageModal(item.image)}/>
                        }
                      </div>
                      <div className="w-full sm:w-[calc(100%-160px)]">
                        <div className="w-full flex justify-between">
                          <div className="w-auto">
                            <h4 className="font-semibold text-xl text-[#333]">{item?.name}</h4>
                            <div className="flex gap-10 mt-3">
                              <div>
                                <span className="text-[#818181] text-[10px]">Price per lbs</span>
                                <b className="flex gap-1 font-normal items-center text-[#666666] text-xl"><img src="/images/grey_dollar_icon.svg" alt="" />{"$" + (parseFloat(item?.pricePerLbs) || 0).toFixed(2)}</b>
                              </div>
                            </div>
                          </div>
                          <div style={{display:'flex',flexDirection:'column'}}>
                            <Link to={`/orders/fish-details/${item.catchId}`} >
                              <a className="text-[#161941] text-base">Show Details</a>
                            </Link>
                            <Link to={`/orders/fish-details-2/${item.catchId}`} >
                              <a className="text-[#161941] mt-7 underline hover:no-underline text-right block text-base">Track Fish</a>
                            </Link>
                          </div>
                        </div>
                        <div className="mt-4 sm:mt-6">
                          <b className="text-[#161941] text-xl font-semibold">{`$${(parseFloat(item?.price) || 0).toFixed(2)}`}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ml-auto xl:ml-0 w-full sm:w-[400px]">
              <div className="rounded-[10px] overflow-hidden">
                <div className="w-full mb-[10px]" style={{display: "flex", justifyContent: "space-between", alignitems: "center"}}>
                  <h3 className="font-bold text-lg sm:text-xl">Order Summary</h3>
                  <span  onClick={handlePrint}><img src="/images/catch_summary_icon.svg" alt="Catch Summary Icon" /></span>
                </div>
                <div className="w-full rounded-[10px] mb-5 bg-white">
                  <div className="w-full py-2 px-4">
                    <span className="text-[#4B5563] font-semibold text-sm">Items ({adminBuyerOrderDetail?.items?.length})</span>
                    <ul className="p-4 border-b border-[#A2A6DE] pb-3 w-full">
                      {adminBuyerOrderDetail?.items?.map((item) =>
                        <li className="flex text-sm text-[#4B5563] w-full justify-between"><span>{item?.name}</span> <b>{`$${(parseFloat(item?.price) || 0).toFixed(2)}`}</b></li>)}
                    </ul>
                    <div className="flex justify-between mt-4 items-center">
                      <span className="text-[#4B5563] font-bold text-sm">Total Price</span>
                      <span className="text-[#4B5563] font-bold text-sm">{`$${(parseFloat(adminBuyerOrderDetail?.totalAmount) || 0).toFixed(2)}`}</span>
                    </div>
                  </div>
                </div>
                <div className="w-full mb-[10px]">
                  <h3 className="font-bold text-lg sm:text-xl">Delivery Estimation</h3>
                </div>
                <div className="w-full rounded-[10px] bg-white">
                  <div className="w-full p-4">
                    <div className="w-full mb-4">
                      <label htmlFor="delivery-date" className="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">Order Delivery Date</label>
                      <input
                        type="text"
                        className="w-full h-[46px] text-sm sm:text-base rounded-lg bg-[#F6F6F6] px-3"
                        id="delivery-date"
                        value={adminBuyerOrderDetail?.delieveryEstimation}
                        readOnly
                      />
                    </div>
                    <div>
                      <label htmlFor="delivery-time" className="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">Order Delivery Time</label>
                      <input
                        type="text"
                        className="w-full h-[46px] text-sm sm:text-base rounded-lg bg-[#F6F6F6] px-3"
                        id="delivery-time"
                        value={`${adminBuyerOrderDetail.delieveryStartTime.substring(11, 16)} - ${adminBuyerOrderDetail.delieveryEndTime.substring(11, 16)}`}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className={`w-full flex justify-center rounded-[10px] items-center h-[45px] sm:h-auto sm:py-4 px-7 mt-5  ${url === "completed-details" ? "bg-[#CED1FB]" : "bg-[#161941]"}`}>
                  <span className={`${url === "completed-details"? "text-[#161941]": "text-[white]"} text-base font-bold`} style={{cursor:"pointer"}} onClick={() => handlePaymentStatus()}>
                    {url === "completed-details" ?
                      "Payment Processed" : statusLoading ? <div className="upload_loader"></div> : "Complete Order"
                    }
                  </span>
                </div>
              </div>
            </div>
            {imageModal && 
            <ImageModal image = {image} setImageModal = { setImageModal}/>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default OrderInfoPage;
