import { gql } from '@apollo/client';

export const GET_APPROVED_SELLER = gql`
  query GetApprovedSellers($limit: Int!, $page: Int!, $search: String!) {
    getApprovedSellers(page: $page, limit: $limit, search: $search) {
      total
      perPage
      currentPage
      totalPages
      approvedSellers {
        id
        name
        image
        email
        ein
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
    }
  }
`;

export const GET_SELLER_REQUESTS = gql`
  query GetSellerRequests($limit: Int!, $page: Int!) {
    getSellerRequests(limit: $limit, page: $page) {
      perPage
      currentPage
      totalPages
      total
      sellerRequests {
        requestId
        name
        ein
        id
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
    }
  }
`;

export const GET_SELLER_REQUEST_BY_ID = gql`
  query GetSellerRequestDetail($id: ID!) {
    getSellerRequestDetail(id: $id) {
      id
      personalInfo {
        name
        email
        image
        phoneNumber {
          dialCode
          number
          countryCode
        }
        address
        bio
      }
      companyInfo {
        name
        website
        logo
        species
        state
        ein
        stateLicenseNumber
        federalLicenseNumber
      }
    }
  }
`;

export const CHANGE_STATUS = gql`
  query ChangeStatus($id: ID!, $status: String!) {
    changeStatus(id: $id, status: $status) {
      message
    }
  }
`;

export const GET_SELLER_CATCHES = gql`
  query GetSellerCatches($page: Int!, $limit: Int!, $sellerId: String!, $search: String = "blank") {
    getSellerCatches(page: $page, limit: $limit, sellerId: $sellerId, search: $search) {
      total
      perPage
      currentPage
      totalPages
      sellerCatches {
        id
        name
        image
        tagNumber
        weight
        dateTime
      }
    }
  }
`;
