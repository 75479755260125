
import {useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { ADMIN_QUERY } from '../../queries/AuthQuery';
import {useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

const HeaderPage = ({ name, icon, toggleSidebar, setOverlayActive }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userTimeZone, setUserTimeZone] = useState('');
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const { previousUrl } = useSelector((state)=> state.auth);

    const { loading: queryLoading, error: queryError, data: userData, refetch } = useQuery(ADMIN_QUERY, {
        variables: { timeZone: userTimeZone }
    });

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setOverlayActive(!isDropdownOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("admin");
        localStorage.removeItem("forgotEmail");
        localStorage.removeItem("activeIndex");
        navigate("/");
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setOverlayActive(false);
        }
    };

    useEffect(()=>{
        refetch();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setUserTimeZone(timeZone);
    },[refetch])

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    const { admin } = userData || {};

   const handleImageClick = () => {
    if (previousUrl) {
      navigate(previousUrl);
    }
  };


   
    return (
        <div className="bg-[#FAFBFF] px-[15px] header-main pb-0 lg:pb-6 gap-y-10 lg:gap-y-0 py-[15px] lg:py-9 lg:px-6 lg:pt-6 xl:px-12 flex-wrap lg:flex-nowrap justify-between flex items-center sticky top-0 left-0 z-30">
            <button type="button" className="text-lg order-1 p-2 bg-[#161941] rounded-md text-gray-900 font-semibold sidebar-toggle lg:hidden" onClick={toggleSidebar}>
                <img src="/images/bar_icon.svg" className="brightness-0 invert" alt="Sidebar Toggle" />
            </button>
            <div className="order-4 pb-4 flex lg:pb-0 w-full lg:order-1 lg:w-auto">
                <h2 className="text-primary text-[22px] sm:text-2xl sm:text-[26px] font-semibold">
                    <a className="flex items-center justify-start gap-4 text-primary text-[22px] sm:text-[26px] font-semibold">
                        <img className="cursor-pointer" src={icon} alt="" onClick={() => navigate(-1)} />
                        {name}
                    </a>
                </h2>
            </div>
            <div className="order-2 pl-[10px] lg:hidden">
                <a href="#"><img className="w-[110px] sm:w-[150px]" src="/images/dashboard_logo.svg" alt="" /></a>
            </div>
            <ul className="order-3 lg:order-2 md:m-0 lg:ml-auto flex items-center">
                <li className="dropdown ml-3 relative" ref={dropdownRef}>
                    <button type="button" className="dropdown-toggle flex items-center" onClick={toggleDropdown}>
                        <div className="flex-shrink-0 w-[46px] h-[46px] sm:w-[50px] sm:h-[50px] relative">
                            <div className="bg-white w-full h-full rounded-full focus:outline-none focus:ring">
                                <img className="w-full h-full rounded-full" src={admin?.image} alt="Profile" />
                            </div>
                        </div>
                    </button>
                    <ul className={`absolute right-0 mt-2 w-[254px] cursor-pointer rounded-[10px] dropdown-menu shadow-md shadow-black/5 z-50 py-1.5 bg-white border border-gray-100 ${isDropdownOpen ? '' : 'hidden'}`}>
                        <div className="text-center border-b border-[#C4C4C4] py-5">
                            <span className="mx-auto block w-20 h-20 rounded-full">
                                <img className="w-full h-full rounded-full object-cover mx-auto" src={admin?.image} alt="Profile" />
                            </span>
                            <span className="block mt-4 font-semibold text-lg">{admin?.firstName} {admin?.lastName}</span>
                            <p className="text-sm text-[#828282]">{admin?.email}</p>
                        </div>
                        <span className="w-0 h-0 absolute -top-[10px] right-[15px] border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-white"></span>
                        <li className="my-4">
                            <a href="/settings" className="flex gap-3 items-center text-base px-4 text-[#4F4F4F] font-medium">
                                <img src="/images/profile_icon.svg" alt="Profile Icon" /> My Profile
                            </a>
                        </li>
                        <li className="mb-4">
                            <div onClick={handleLogout}>
                                <a role="menuitem" className="flex gap-3 items-center text-base px-4 text-[#EB5757] cursor-pointer">
                                    <img src="/images/logout_icon.svg" alt="Logout Icon" /> Log Out
                                </a>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default HeaderPage;
