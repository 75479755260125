import BuyersDetailPage from "../Layout/BuyersDetail";
import BuyerOrderDetailPage from "../Layout/BuyersOrderDetailPage";
import BuyersPage from "../Layout/BuyersPage";
import CatchDetailPage from "../Layout/CatchDetailPage";
import CatchOfTheDayPage from "../Layout/CatchOfTheDayPage";
import DashboardPage from "../Layout/DashboardPage";
import FishInventoryDetailPage from "../Layout/FistInventoryDetailPage";
import FishInventoryPage from "../Layout/FistInventoryPage";
import OrderInfoPage from "../Layout/OrderInfoPage";
import OrderPage from "../Layout/OrderPage";
import SellerCatchesPage from "../Layout/SellerCatchesPage";
import SellerDetailPage from "../Layout/SellerDetailPage";
import SellerRequestDetailPage from "../Layout/SellerRequestDetailPage";
import SellerRequestPage from "../Layout/SellerRequestPage";
import SellersPage from "../Layout/SellersPage";
import Settings from "../Layout/SettingsPage";
import SubAdminPage from "../Layout/SubAdminPage";

const baseRoutes = [
  {
    name:'Dashboard',
    path: '/dashboard',
    icon:'',
    component: DashboardPage
  },
  {
    name:'Sub Admin',
    path: '/sub-admin',
    icon:'',
    component: SubAdminPage
  },
  {
    name:'Orders',
    path: '/orders/:page',
    icon:'',
    component: OrderPage
  },
  {
    name:'Order Details',
    path: '/orders/:openTab-details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: OrderInfoPage
  },
  {
    name:'Fish Detail',
    path: '/orders/fish-details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: CatchDetailPage
  },
  {
    name:'Fish Detail',
    path: '/orders/fish-details-2/:id',
    icon:'/images/back_arrow_icon.svg',
    component: CatchDetailPage
  },
  { 
    name:'Catch Of The Day',
    path: '/catch-of-the-day/:page',
    icon:'',
    component: CatchOfTheDayPage
  },
  {
    name:'Catch Of The Day',
    path: '/catch-of-the-day/details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: CatchDetailPage
  },
  {
    name:'Fish Inventory',
    path: '/fish-inventory',
    icon:'',
    component: FishInventoryPage
  },
  {
    name:'Fish Inventory',
    path: '/fish-inventory/details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: FishInventoryDetailPage
  },
  {
    name:'Catch Of The Day',
    path: '/catch-of-the-day/fish-inventory/:id',
    icon:'/images/back_arrow_icon.svg',
    component: CatchDetailPage
  },
  {
    name:'Seller Request',
    path: '/seller-request/:page',
    icon:'',
    component: SellerRequestPage
  },
  {
    name:'Seller Request',
    path: '/seller-request/details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: SellerRequestDetailPage
  },
  {
    name:'Sellers',
    path: '/sellers/:page',
    icon:'',
    component: SellersPage
  },
  {
    name:'Sellers',
    path: '/sellers-catches/:id/:page',
    icon:'',
    component: SellerCatchesPage
  },
  {
    name:'Sellers',
    path: '/sellers/details/:id',
    icon:'/images/back_arrow_icon.svg',
    component: SellerDetailPage
  },
  {
    name:'Sellers',
    path: '/seller/catch-details/:id',
    icon:'',
    component: CatchDetailPage
  },
  {
    name:'Buyers',
    path: '/buyers/:page',
    icon:'',
    component: BuyersPage
  },
  {
    name:'Buyers',
    path: '/buyers/orders/:id',
    icon:'/images/back_arrow_icon.svg',
    component: BuyerOrderDetailPage
  },
  {
    name:'Buyers',
    path: '/buyers/detail/:id',
    icon:'/images/back_arrow_icon.svg',
    component: BuyersDetailPage
  },
  {
    name:'Settings',
    path: '/settings',
    icon:'',
    component: Settings
  },
  
];


export default baseRoutes;

