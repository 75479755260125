import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { GET_CATCHES_BY_FISH } from "../../queries/FistInventoryQuery";
import { useEffect, useState } from "react";
import InlineLoader from "../utils/InlineLoader";
import FooterPage from "./FooterPage";
import moment from "moment";

const FishInventoryDetailPage = () => {
    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [searchString, setSearchString] = useState("blank");
    const limit = 15;

    const { loading, error, data, refetch} = useQuery(GET_CATCHES_BY_FISH, {
        variables: { page, limit, fishId: id, search: searchString },
    });

    useEffect(()=>{
        refetch();
    },[id, refetch])

    const { getCatcheByFish } = data || {};

    return (
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
            <div className="grid grid-cols-1 lg:grid-cols-1 mb-6">
                <div className="w-full border-b border-b-[#EDEDED] p-4 sm:py-[15px] sm:px-[30px] bg-white flex items-center justify-between">
                    <div className="relative w-full sm:w-[300px]">
                        <input
                            className="text-xs font-medium px-9 w-full h-[40px] bg-[#FDFDFD] border border-solid border-[#E0E0E0] rounded-[10px]"
                            type="search"
                            placeholder="Search by name"
                        />
                        <span className="absolute top-1/2 -translate-y-1/2 left-[15px]">
                            <img src="/images/search_icon.svg" alt="Search Icon" />
                        </span>
                    </div>
                </div>
                <div className="bg-white h-[45vh] sm:h-[50vh] lg:h-[65vh] mb-0 overflow-y-auto">
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[700px]">
                            <thead>
                                <tr>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                        Seller
                                    </th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                                        Weight
                                    </th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                        Tag Number
                                    </th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                        Date
                                    </th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading || error || getCatcheByFish?.catches?.length === 0 ? (
                                    <tr>
                                        <td colSpan={7}  className="h-auto md:h-[450px] ">
                                            {loading ? (
                                                <InlineLoader />
                                            ) : error ? (
                                                <p className='error_text'>Error: {error.message}</p>
                                            ) : (
                                                <p className='error_text' >
                                                    <img src="/images/fish-illustration.svg" alt=" " />
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                ) : ""}

                                {getCatcheByFish?.catches?.map((catche) =>
                                    <tr>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{catche?.sellerName}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-8 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{catche?.weight + "lbs"}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{catche?.tagNumber}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">
                                                {moment(catche?.dateTime).format('MM/DD/YYYY')}
                                                {/* <div className="text-xs text-[#828282] block pl-4 pt-1">
                                                    10:12 AM
                                                </div> */}
                                            </span>
                                        </td>
                                        <td className="py-5 px-11 border-b border-b-[#EDEDED] text-right">
                                            <Link to={`/catch-of-the-day/fish-inventory/${catche.id}`}>
                                                <button className="text-base w-auto">
                                                    <img src="/images/action_eye_icon.svg" alt="View" />
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <FooterPage
                    currentPage={page}
                    totalPages={getCatcheByFish?.totalPages}
                    onPageChange={setPage}
                    perPage={getCatcheByFish?.catches?.length}
                    totalValue = {getCatcheByFish?.total}
                />
            </div>
        </div>
    );
};

export default FishInventoryDetailPage;
