import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { EXPORT_FISH_INVENTORY_QUERY, GET_FISH_INVENTORIES, HIDE_INVENTORY } from "../../queries/FistInventoryQuery";
import { useEffect, useState } from "react";
import InlineLoader from "../utils/InlineLoader";
import SpeciesForm from "./AddSpeciesPage";
import EditSpeciesForm from "./EditViewSpeciesPage";

const FishInventoryPage = () => {
    const { loading, error, data, refetch } = useQuery(GET_FISH_INVENTORIES);
    const [hideUnhideFishInventory, { data: speciesData, loading: speciesLoading, error: speciesError }] = useMutation(HIDE_INVENTORY);
    const [openPopup, setOpenPopup] = useState(false);
    const [openSpecies, setOpenSpecies] = useState(false);
    const [fishStatus, setFishStatus] = useState(false);
    const [pageType, setPageType] = useState();
    const [exportLoder, setExportLoader] = useState(false);
    const [fishId, setFishId] = useState();
    const { data: exportData, loading: isLoading, error: fetchError } = useQuery(EXPORT_FISH_INVENTORY_QUERY);

    const { getFishInventories } = data || {};

    const handleOpenAddSpecies = (type, id) => {
        setOpenSpecies(true);
        setPageType(type);
        setFishId(id);
    }

    useEffect(() => {
        if (!openSpecies) {
            refetch();
        }
    })

    const handleClose = () => {
        setOpenSpecies(false);
    }

    const handleOpenHidePopup = (id, status) => {
        setOpenPopup(true);
        setFishId(id);
        setFishStatus(status)
    }

    const handleHideSpecies = async () => {
        try {
            const response = await hideUnhideFishInventory({
                variables: {
                    id: fishId,
                    status: !fishStatus
                }
            });
            setOpenPopup(false)
        } catch (err) {
            console.error('Error hiding/unhiding inventory:', err);
        }
    }

    const handleClickFile = async () => {
        if (fetchError) {
            console.error(fetchError);
            return;
        }
        if (exportData && exportData?.exportFishInventories) {
            setExportLoader(true);
            try {
                const response = await fetch(exportData?.exportFishInventories.path, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'Access-Control-Allow-Origin': '*',
                        "Cache-Control": "no-cache, no-store, must-revalidate",
                        "Pragma": "no-cache",
                        "Expires": "0"
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to download file');
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exportedFile.xlsx');

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setExportLoader(false);
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        }
    };

    return (
        <>
            <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
                <div className="w-full flex justify-end mb-16">
                    <button className="bg-[#161941] flex items-center justify-center rounded-[10px] py-2 px-4 text-white text-sm font-semibold" onClick={handleClickFile}>{exportLoder ? <div className="upload_loader" style={{ marginLeft: "0%" }}></div> :
                        <>
                            <img
                                src="/images/export_upload_icon.svg"
                                alt="icon"
                                style={{marginRight:"3px"}}
                            />

                            Export
                        </>
                    }
                    </button>
                    <button className="bg-[#161941] flex items-center justify-center rounded-[10px] py-2 px-4 mx-2 text-white text-sm font-semibold" onClick={() => handleOpenAddSpecies("add", "")}>+ Add Species</button>
                </div>
                {loading || error || getFishInventories?.length === 0 ? (
                    <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] flex justify-center items-center" style={{ height: "60vh" }}>
                        {loading ? (
                            <InlineLoader />
                        ) : error ? (
                            <p className='error_text'>Error: {error.message}</p>
                        ) : (
                            <p className='error_text' >
                                <img src="/images/empty_data.svg" alt=" " />
                            </p>
                        )}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-20 mb-6 gap-8">
                        {getFishInventories?.map((inventory) =>
                            <div className="relative shadow-xl bg-white rounded-lg p-5">
                                <span className="absolute -top-[30px] sm:-top-[50px] right-0">
                                    {loading ?
                                        <div className='shimmer w-36 sm:w-48 h-[70px] sm:h-[120px] object-cover'></div>
                                        :
                                        <img className="w-36 sm:w-48 h-[70px] sm:h-[120px] object-contain" src={inventory?.image || "/images/fish_blue.png"} alt="Blue Marlin" />
                                    }
                                </span>
                                <h3 className="text-[#161941] font-semibold text-[24px] w-[130px]">{inventory?.speciesName}</h3>
                                <div className="flex mt-3 mb-4 justify-between">
                                    <div>
                                        <span className="text-black text-[9px]">Seller Price</span>
                                        <b className="text-lg text-black flex items-center gap-1"><img className="w-5 h-5" src="/images/dollar_icon.svg" alt="Seller Price" /> {`${(parseFloat(inventory?.sellerPrice) || 0).toFixed(2)}`}</b>
                                    </div>
                                    <div>
                                        <span className="text-black text-[9px]">Buyer Price</span>
                                        <b className="text-lg text-black flex items-center gap-1"><img className="w-5 h-5" src="/images/dollar_icon.svg" alt="Buyer Price" />{`${(parseFloat(inventory?.buyerPrice) || 0).toFixed(2)}`}</b>
                                    </div>
                                    <div>
                                        <span className="text-black text-[9px]">Quantity in stock</span>
                                        <b className="text-lg text-black flex items-center gap-1"><img className="w-4 h-4" src="/images/total_icon.svg" alt="Quantity in stock" /> {inventory?.quantityInStock + "lbs"}</b>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <button className="w-7 h-7 border-[2px] border-[#CCCCCC] rounded-md flex items-center justify-center" onClick={() => handleOpenAddSpecies("view", inventory?.id)}><img src="/images/eye_icon_fish.svg" alt="View" /></button>
                                        <button className="w-7 h-7 border-[2px] border-[#CCCCCC] rounded-md flex items-center justify-center" onClick={() => handleOpenAddSpecies("edit", inventory?.id)}><img src="/images/edit_icon.svg" alt="Edit" /></button>
                                        <button className="w-7 h-7 border-[2px] border-[#CCCCCC] rounded-md flex items-center justify-center" onClick={() => handleOpenHidePopup(inventory?.id, inventory?.isHide)}><img src={inventory?.isHide ? "/images/widget.svg" : "/images/widget1.svg"} alt="Delete" /></button>
                                    </div>
                                    <div>
                                        <Link to={`/fish-inventory/details/${inventory?.id}`}>
                                            <button className="flex items-center gap-3 text-[#161941] text-sm font-medium">View Catches <img src="/images/arrow_right_icon.svg" alt="View Catches" /></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {openSpecies && (
                    pageType === "add" ? (
                        <SpeciesForm
                            pageType={pageType}
                            onClose={handleClose}
                        />
                    ) : (pageType === "edit" || pageType === "view") && (
                        <EditSpeciesForm
                            pageType={pageType}
                            onClose={handleClose}
                            fishId={fishId}
                        />
                    )
                )}
                {openPopup &&
                    <div class="fixed top-0 left-0 w-full h-full bg-black/50 z-50 ">
                        <div
                            class="w-[340px] px-6 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center"
                        >
                            <span class="w-full">
                                <img
                                    class="mx-auto w-auto h-auto"
                                    src={fishStatus ? "images/modal_popup.svg" : "images/modal_popup1.svg"}
                                    alt=""
                                /></span>
                            <h3 class="text-lg font-bold py-3">Please Confirm!</h3>
                            <p class="text-sm">Are you sure you want to {fishStatus ? "unhide" : "hide"} this species?</p>
                            <div class="mt-3 flex gap-3 justify-center">
                                <button
                                    class="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-white text-sm font-semibold"
                                    onClick={() => setOpenPopup(false)}
                                >
                                    No
                                </button>
                                <button
                                    class="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold"
                                    onClick={() => handleHideSpecies()}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    );
};

export default FishInventoryPage;
