import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('authToken') || null,
    user: null,
    showSideBar: false,
    currentUrl: '',      
    previousUrl: '',
    currentPage:1,
  },
  reducers: {
    setCredentials(state, action) {
      state.token = action.payload.token;
      localStorage.setItem('authToken', action.payload.token);
    },
    logout(state) {
      state.token = null;
      localStorage.removeItem('authToken');
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setShowSideBar(state, action) {
      state.showSideBar = action.payload;
    },
    setCurrentUrl(state, action) {
      state.previousUrl = state.currentUrl;
      state.currentUrl = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
});

export const { setCredentials, logout, setUser, setShowSideBar,setCurrentUrl, setCurrentPage} = authSlice.actions;

export default authSlice.reducer;
