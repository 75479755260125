import React, { useEffect, useRef, useState } from 'react';
import { VERIFY_OTP } from '../queries/AuthQuery';
import { FORGOT_PASSWORD } from '../queries/AuthQuery';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../utils/ToastNotification';

const VerifyMail = () => {
  const [verifyOtp, { data, loading, error }] = useMutation(VERIFY_OTP);
  const [forgot,{  loading:forgotLoading ,error:forgotError }] = useMutation(FORGOT_PASSWORD);
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const otpInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const forgotEmail = localStorage.getItem("forgotEmail")

  const handleChange = (index, value) => {
    const newOtpDigits = [...otpValue];
    const customValue = value.substring(value.length - 1);
    newOtpDigits[index] = customValue;
    setOtpValue(newOtpDigits);
    if (value === "" && index > 0) {
      otpInputRefs[index - 1].current.focus();
    } else if (value && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  const handleBackspaceOrClear = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      if (e.key === "Backspace" && index > 0) {
        otpInputRefs[index - 1].current.focus();
      }
      const newOtpDigits = [...otpValue];
      newOtpDigits[index] = "";
      setOtpValue(newOtpDigits);
    }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otp = otpValue.join('');
    if (!otp) {
      ToastNotification({ message: 'Please enter your OTP.', type: 'error' });
      return;
    }
    try {
      const { data } = await verifyOtp({
        variables: { email: forgotEmail, otp: otp },
      });

      if (data.verifyOtpForResetPassword.message) {
        setTimeout(() => {
          navigate('/reset-password');
        }, 1000);  
      } else {
        console.error('OTP verification failed:', data.verifyOtpForResetPassword.message);
      }
    } catch (err) {
      console.error('Error during OTP verification', err);
      ToastNotification({ message: err.message, type: 'error' });
    }
  };

  const handleResendOtp = async (e) => {
    try {
      const { data } = await forgot({
        variables: { email: forgotEmail }
      });
    } catch (err) {
      console.error('Password reset failed', err);
    }
  };

  return (
    <div className="bg-white flex items-center h-screen justify-center">
        <Toaster position="top-right" reverseOrder={false} />
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[110px]" onClick={()=> navigate(-1)} >
          <button className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
            <img src="/images/back_arrow_icon.svg" alt="" />
          </button>
        </div>
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <a href="#" className="static sm:fixed top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img className="mx-auto sm:mx-0 w-[110px] xl:w-[120px]" src="/images/logo_new.png" alt="" />
          </a>
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px] text-[#4F4F4F]">Verifying Email</h2>
            <p className="text-[#4F4F4F] text-[16px]">Please enter the OTP sent to your email</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-between">
              {otpValue.map((digit, index) => (
                <input
                type="text"
                id={`digit${index + 1}`}
                pattern="[0-9]"
                required
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleBackspaceOrClear(e, index)}
                ref={otpInputRefs[index]}
                key={index}
                className="pl-[18px] md:pl-[25px] xl:pl-[30px] font-bold text-base md:w-[60px] md:h-[60px] w-[45px] h-[45px] lg:w-[55px] lg:h-[55px] xl:w-[70px] xl:h-[70px] border border-solid rounded-lg border-[#E0E0E0]"
                />
              ))}
            </div>
            <div className="w-full mt-[15px] xl:mt-[34px]">
              <button type="submit" className="w-full rounded-[10px] font-bold bg-[#161941] text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px] xl:h-[70px]">{loading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "SUBMIT"
                    )}</button>
            </div>
            <div className="mt-[10px] w-full md:mt-[20px] text-center">
              <p className="flex justify-center w-full gap-3 text-gray2 text-sm md:text-lg">Didn't receive the email? <a className="text-[#161941] font-bold" style={{cursor:"pointer"}}  onClick={handleResendOtp}>{forgotLoading? <div className="upload_loader"></div> :"Click to resend"}</a></p>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src="/images/new-image.png" alt="" />
      </div>
    </div>
  );
};

export default VerifyMail;
