import { Link } from "react-router-dom";
import { GET_SELLER_REQUESTS } from "../../queries/SellerQuery";
import { useQuery } from "@apollo/client";
import FooterPage from "./FooterPage";
import { useEffect, useState } from "react";
import InlineLoader from "../utils/InlineLoader";
import { useSelector } from 'react-redux';

const SellerRequestPage = () => {
    const { currentPage } = useSelector((state)=> state.auth);
    const [page, setPage] = useState(currentPage);
    const limit = 15;


    const { loading, error, data, refetch} = useQuery(GET_SELLER_REQUESTS, {
        variables: { limit, page },
    });

    useEffect(() => {
        refetch();
      }, [page, refetch]);
      
    const { getSellerRequests } = data || {};

    return (
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
            <div className="grid grid-cols-1 lg:grid-cols-1">
                <div className="bg-white h-[55vh] sm:h-[60vh] lg:h-[73vh] overflow-y-auto">
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[900px]">
                            <thead>
                                <tr>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">Request ID</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">Name</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">EIN</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">Phone Number</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading || error || getSellerRequests?.sellerRequests?.length === 0 ? (
                                     <tr>
                                     <td colSpan={7} style={{ height: 450 }}>
                                       {loading ? (
                                         <InlineLoader />
                                       ) : error ? (
                                         <p className='error_text'>Error: {error.message}</p>
                                       ) : (
                                         <p className='error_text' >
                                           <img src="/images/seller_image.svg" alt=" " />
                                         </p>
                                       )}
                                     </td>
                                   </tr>
                                 ) : 
                                getSellerRequests && getSellerRequests?.sellerRequests?.map((sellerRequest) =>
                                    <tr>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{sellerRequest.requestId}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{sellerRequest.name}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{sellerRequest.ein}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{sellerRequest.phoneNumber.dialCode}{sellerRequest.phoneNumber.number}</span>
                                        </td>
                                        <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                                            <Link to={`/seller-request/details/${sellerRequest.id}`}>
                                                <button className="text-sm sm:text-base w-auto">
                                                    <img src="/images/action_eye_icon.svg" alt="View" />
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <FooterPage
                    currentPage={page}
                    totalPages={getSellerRequests?.totalPages}
                    onPageChange={setPage}
                    perPage={getSellerRequests?.sellerRequests?.length}
                    totalValue = {getSellerRequests?.total}
                />
            </div>
        </div>
    );
};

export default SellerRequestPage;
