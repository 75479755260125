import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import baseRoutes from "./RouteConfig";
import CommonPage from "../Layout/CommonPage";
import PrivateRoute from "./PrivateRoute/PrivateRoute";

const AllRoutes = (props) => {
  return (
    <Fragment>
      <Routes>
        {baseRoutes.map((route, i) => {
          let Component = route.component; 
          return (
            <Route
              key={i}
              {...route}
              element={
                <CommonPage name={route.name} icon={route.icon}>
                  <PrivateRoute>
                    <Component /> 
                  </PrivateRoute>
                </CommonPage>
              }
            />
          );
        })}
      </Routes>
    </Fragment>
  );
};

export default AllRoutes;
