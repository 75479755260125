import {useQuery } from "@apollo/client";
import { GET_DASHBOARD_DATA } from "../../queries/DashboardQuery";
import InlineLoader from "../utils/InlineLoader";
import { useEffect } from "react";

const DashboardPage = () => {
    const  { loading, error, data, refetch} = useQuery(GET_DASHBOARD_DATA);

    useEffect(()=>{
        refetch();      
    },[refetch])

    const { totalSales, totalFishSpecies, totalBuyers, totalSellers } = data?.getDashboardData || {};

    return (
        <>
            {loading || error || !data ? (
                <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
                    {loading ? (
                        <InlineLoader />
                    ) : error ? (
                        <p className='error_text'>Error: {error.message}</p>
                    ) : (
                        <p className='error_text'>No data available</p>
                    )}
                </div>
            ) : (
                <div className="md:pt-0 lg:pt-6 p-4 lg:p-6 xl:pt-9 xl:p-12 bg-[#FAFBFF]">   
                    <div className="grid w-full pb-40 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-5 md:gap-5 lg:gap-9 ">
                        <div className="bg-white h-32 sm:h-36 items-center justify-between p-4 sm:p-7 px-4 shadow-md rounded-[10px] flex">
                            <div>
                                <span className="mb-5 block text-[#202224] text-base font-semibold">Total Buyers</span>
                                <b className="font-bold text-[25px] text-[#202224]">{totalBuyers}</b>
                            </div>
                            <span className="bg-[#DDDFFB] w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] flex items-center justify-center rounded-3xl">
                                <img src="/images/buyers_icon.svg" alt="" />
                            </span>
                        </div>
                        <div className="bg-white h-32 sm:h-36 items-center justify-between p-4 sm:p-7 px-4 shadow-md rounded-[10px] flex">
                            <div>
                                <span className="mb-5 block text-[#202224] text-base font-semibold">Total Sellers</span>
                                <b className="font-bold text-[25px] text-[#202224]">{totalSellers}</b>
                            </div>
                            <span className="bg-[#DDDFFB] w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] flex items-center justify-center rounded-3xl">
                                <img src="/images/sellers_icon.svg" alt="" />
                            </span>
                        </div>
                        <div className="bg-white h-32 sm:h-36 items-center justify-between p-4 sm:p-7 px-4 shadow-md rounded-[10px] flex">
                            <div>
                                <span className="mb-5 block text-[#202224] text-base font-semibold">Total Fish Species</span>
                                <b className="font-bold text-[25px] text-[#202224]">{totalFishSpecies}</b>
                            </div>
                            <span className="bg-[#DDDFFB] w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] flex items-center justify-center rounded-3xl">
                                <img src="/images/fish_icon.svg" alt="" />
                            </span>
                        </div>
                        <div className="bg-white h-32 sm:h-36 items-center justify-between p-4 sm:p-7 px-4 shadow-md rounded-[10px] flex">
                            <div>
                                <span className="mb-5 block text-[#202224] text-base font-semibold">Total Sales</span>
                                <b className="font-bold text-[25px] text-[#202224]">{"$"+ totalSales.toFixed(2)}</b>
                            </div>
                            <span className="bg-[#DDDFFB] w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] flex items-center justify-center rounded-3xl">
                                <img src="/images/dollar_icon.svg" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardPage;
