const ImageModal = ({ image, setImageModal }) => {

    const handleCancelClick = () => {
        setImageModal(false);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="relative w-max h-[80%] bg-white rounded-md p-10">
                <button
                    className="absolute top-2 right-2 text-black"
                    onClick={handleCancelClick}>
                    <img src='/images/times_icon.svg' alt="Close" />
                </button>
                <div className="flex justify-center items-center h-full">
                    <img src={image} alt="Modal Content" className="max-w-full max-h-full" />
                </div>
            </div>
        </div>
    );
}

export default ImageModal;
