import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setCurrentUrl, setShowSideBar } from "../../redux/slices/authSlice";

const SideBarPage = ({ isSidebarVisible, hideSidebar, changeSideBar }) => {
  const { user } = useSelector(state => state.auth);
  console.log(user,"=========");
  const location = useLocation();
  const url = window.location.href;
  const dispatch = useDispatch()
 
  const { showSideBar, currentPage , currentUrl} = useSelector((state)=>state.auth);

  const getBaseUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname; 
      return pathname;
    } catch (error) {
      console.error('Invalid URL:', error);
      return ''; 
    }
  };
  
  const baseUrl = getBaseUrl(url);
  
  useEffect(()=>{
    dispatch(setCurrentUrl(baseUrl));
  },[baseUrl])

  const menuItems = [
    {
      label: "Dashboard",
      icon: "/images/dashboard_icon.svg",
      link: "/dashboard",
    },
    {
      label: "Sub Admin",
      icon: "/images/sub_admin_icon.svg",
      link: "/sub-admin",
    },
    { label: "Orders", 
      icon: "/images/orders_icon.svg", 
      link: `/orders/${currentPage}` 
    },
    {
      label: "Catch of the day",
      icon: "/images/catch_icon.svg",
      link: `/catch-of-the-day/${currentPage}`,
    },
    {
      label: "Fish Inventory",
      icon: "/images/fish_icon.svg",
      link: "/fish-inventory",
    },
    {
      label: "Seller Requests",
      icon: "/images/seller_icon.svg",
      link: `/seller-request/${currentPage}`,
    },
    { label: "Sellers", 
      icon: "/images/sellers_icon.svg", 
      link: `/sellers/${currentPage}` 
    },
    { label: "Buyers", 
      icon: "/images/buyers_icon.svg", 
      link: `/buyers/${currentPage}` 
    },
    { label: "Settings", icon: "/images/setting_icon.svg", link: "/settings" },
  ];

  const filteredMenuItems = user?.role === 'subadmin'
  ? menuItems.filter(item => item.label !== "Sub Admin")
  : menuItems;

  useEffect(() => {
    const savedIndex = localStorage.getItem('activeIndex');
    if (savedIndex !== null) {
      setActiveIndex(parseInt(savedIndex, 10));
    } else {
      const currentPath = location.pathname;
      const currentIndex = filteredMenuItems.findIndex((item) => item.link === currentPath);
      if (currentIndex !== -1) {
        setActiveIndex(currentIndex);
      }
    }
  }, [location?.pathname]);

  const [activeIndex, setActiveIndex] = useState(() => {
    const savedIndex = localStorage.getItem('activeIndex');
    return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
  });

  const handleClick = (index) => {
    setActiveIndex(index);
    localStorage.setItem('activeIndex', index);
  };
  
  const handleOpenSideBar = () => {
    dispatch(setShowSideBar(!showSideBar));
  };

  useEffect(()=>{
    changeSideBar(false)
  },[location?.pathname])

  return (
    <div
      className={`overflow-y-auto fixed left-0 top-0 w-64 h-full shadow-md shadow-black/10 bg-white py-6 px-7 z-50 sidebar-menu transition-transform lg:translate-x-0 ${
        isSidebarVisible ? "translate-x-0" : "-translate-x-full"
      } `}
    >
      {" "}
      <div className="flex side-btn-main justify-between w-full pr-0 mb-14">
        <a className="flex  items-center side-logo">
          <img className="w-[121px]" src="/images/dashboard_logo.svg" alt="Logo" />
        </a>
        <button onClick={() => handleOpenSideBar()}>
          <img src="/images/bar_icon.svg" alt="Toggle Sidebar" />
        </button>
      </div>
      <ul className="mt-4 menu-tabs">
        {filteredMenuItems.map((item, index) => (
          <li key={index} className="mb-3.5 group">
            <Link
              to={item.link}
              onClick={() => handleClick(index)}
              className={`flex gap-3 font-semibold items-center py-[16px] px-[22px] text-primary hover:bg-[#161941] hover:text-white rounded-[10px] ${
                activeIndex === index ? "active-menu" : ""
              }`}
            >
              <img
                className="group-hover:brightness-0 group-hover:invert"
                src={item.icon}
                alt=""
              />
              <span className="text-sm">{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBarPage;
