import { gql } from '@apollo/client';

export const GET_ADMIN_BUYERS = gql`
  query GetAdminBuyers($limit: Int!, $page: Int!, $search: String!) {
    getAdminBuyers(page: $page, limit: $limit, search: $search) {
      total
      perPage
      currentPage
      totalPages
      buyers {
        id
        name
        email
        image
        role
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
    }
  }
`;
export const GET_ADMIN_BUYER_ORDERS = gql`
  query GetAdminBuyerOrders($page: Int!, $limit: Int!, $status: String!, $buyerId: ID! ) {
    getAdminBuyerOrders(page: $page, limit: $limit, status: $status, buyerId: $buyerId) {
      total
      perPage
      currentPage
      totalPages
      orders {
        id
        orderId
        fistItemName
        totalItemsInOrder
        Amount
        orderPlaced
        orderCompleted
      }
    }
  }
`;
export const GET_ADMIN_BUYER_DETAIL = gql`
  query GetAdminBuyerDetail($id: ID!) {
    getAdminBuyerDetail(id: $id) {
      id
      personalInfo {
        name
        email
        image
        address
        ein
        addedOn
        role
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
      companyInfo {
        name
        website
        logo
        certificate
        species
      }
    }
  }
`;