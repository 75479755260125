import React, { useState } from "react";
import { ADD_SUBADMIN } from "../../queries/SubAdminQuery";
import { useMutation } from "@apollo/client";
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';

const AddSubAdmin = ({ pageType, onClose }) => {
  const [addSubadminMutation, { data, loading, error }] = useMutation(ADD_SUBADMIN);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: null,
  });
  const [imagePreview, setImagePreview] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let emptyFields = [];

    if (!formData.firstName) emptyFields.push('First Name');
    if (!formData.lastName) emptyFields.push('Last Price');
    if (!formData.email) emptyFields.push('Email');
    if (!formData.image) emptyFields.push('Image');

    if (emptyFields.length > 0) {
      ToastNotification({ message: `${emptyFields.join(', ')} are required`, type: 'error' });
    }

    setValidationErrors(errors);
    return emptyFields.length === 0;
  };

  const handleAddSubAdmins = async () => {
    try {
      const { data } = await addSubadminMutation({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          image: formData.image
        }
      });
      if (data) {
        ToastNotification({ message: data.addSubadmin.message, type: 'success' });
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      ToastNotification({ message: 'An error occurred while adding subadmin.', type: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target; {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFormData(prevState => ({ ...prevState, image: file }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && pageType === 'add') {
      handleAddSubAdmins();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="w-[90%] sm:w-[640px] lg:w-[870px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 lg:pr-12 lg:py-11 lg:p-6 rounded-[10px] bg-white">
        <div className="flex justify-between items-center border-b border-[#E5E7EB] pb-4 mb-4 lg:pb-6 lg:mb-6">
          <div>
            <h3 className="font-semibold lg:font-bold text-lg lg:text-xl">Add New Sub Admin</h3>
          </div>
          <button onClick={onClose} className="">
            <img src="/images/times_icon.svg" alt="" />
          </button>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 sm:gap-6">
          <div className="w-24 sm:w-36 lg:w-48">
            <div className="relative w-24 h-24 sm:w-36 sm:h-36 lg:w-48 lg:h-48 border-dotted border-[2px] border-[#161941] rounded-sm">
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
                className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
              />
              {formData.image ? (
                <img className="w-full h-full object-cover rounded-sm" src={imagePreview} alt="" />
              ) : (
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <img className="w-8 sm:w-auto" src="/images/placeholder_img.svg" alt="" />
                </span>
              )}
            </div>
            <label className="block mt-2 text-center w-full text-[#6B7280] text-xs sm:font-semibold tracking-[1px]">
              Upload Photo
            </label>
          </div>
          <div className="w-full sm:w-[calc(100%-144px)] lg:w-[calc(100%-192px)]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 sm:gap-6 mb-2 sm:mb-6">
              <div>
                <label className="text-sm mb-1 sm:font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                  First Name
                </label>
                <input
                  className="border-none font-medium bg-[#F6F6F6] text-sm lg:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="text-sm mb-1 sm:font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                  Last Name
                </label>
                <div className="relative">
                  <input
                    className="border-none font-medium bg-[#F6F6F6] text-sm lg:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                    type="text"
                    placeholder="Enter last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                  Email
                </label>
                <div className="relative">
                  <input
                    className="border-none font-medium bg-[#F6F6F6] text-sm lg:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center mt-4 sm:mt-10">
          <button onClick={handleSubmit} className="w-full sm:w-64 h-10 sm:h-12 bg-[#161941] rounded-md text-white sm:font-semibold lg:font-bold text-sm  sm:text-base tracking-[1px]">
            {loading ? <div className="upload_loader" style={{ marginLeft: "50%" }}></div> : "Add new Sub admin"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSubAdmin;
