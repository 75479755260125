import toast from "react-hot-toast";


const ToastNotification = ({ message, type}) => {
  const toastErrorOptions = {
    style: {
      borderRadius: '10px',
    },
  };
  if (type === 'error') {
    toast.error(message, toastErrorOptions);
  } 
};

export default ToastNotification;
