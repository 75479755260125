import { gql } from '@apollo/client';

export const GET_CATCH_DETAIL = gql`
  query CatchDetail($id: ID!) {
    catchDetail(id: $id) {
      id
      paymentStatus
      fishSpeciesImage
      catchInfo {
            fishSpecies
            speciesId
            weight
            sellerPricePerLbs
            buyerPricePerLbs
            tagNumber
            dateTime
            location
            latitude
            longitude
            story
            images
            totalPrice
            buyerTotalPrice
      }
      sellerInfo {
        Id
        name
        email
        image
        phoneNumber {
                dialCode
                number
                countryCode
            }
      }
    }
  }
`;

export const GET_ADMIN_CATCHES = gql`
  query GetAdminCatches($page: Int!, $limit: Int!, $search: String = "blank") {
    getAdminCatches(page: $page, limit: $limit, search: $search) {
      total
      perPage
      currentPage
      totalPages
      catches {
        id
        name
        image
        sellerName
        weight
        dateTime
        tagNumber
      }
    }
  }
`;

export const CATCH_CHANGE_STATUS = gql`
  query catchChangeStatus($id: ID!, $status: String!) {
    catchChangeStatus(id: $id, status: $status) {
      message
    }
  }
`;

export const CHANGE_PAYMENT_STATUS_QUERY = gql`
  query ChangePaymentStatus($id: ID!, $status: String!) {
    changePaymentStatus(id: $id, status: $status) {
      message
    }
  }
`;

export const EDIT_ADMIN_SIDE_CATCH = gql`
  mutation editAdminSideCatch($catchId: ID!, $input: EditAdminSideCatchRequestInput!) {
    editAdminSideCatch(catchId: $catchId, input:$input) {
      message
    }
  }
`;

export const DELETE_ADMIN_CATCH = gql`
  query adminDeleteCatch($catchId: ID!) {
    adminDeleteCatch(catchId: $catchId) {
      message
    }
  }
`;

export const ADMIN_CATCH_TRACK_INFO = gql`
  query AdminCatchesTrackInfo($tagNumber: String!) {
    adminCatchesTrackInfo(tagNumber: $tagNumber) {
      id
      firstName
      lastName
      address
      longitude
      latitude
      statusNote
      createdAt
    }
  }
`;

export const GET_PLACE_ID = gql`
  query GetPlaceId($input: String!) {
    getPlaceId(input: $input) {
      description
      placeId
    }
  }
`;

export const GET_PLACE_DETAILS = gql`
query GetPlaceDetails($placeId: String!) {
  getPlaceDetails(placeId: $placeId) {
    country
    countryCode
    state
    city
    latitude
    longitude
  }
}
`;

export const GET_ALL_FISH_SPECIES = gql`
  query GetAllFishSpecies {
    getAllFishSpecies {
      id
      name
      pricePerLbs
    }
  }
`;