import React, { useEffect, useState } from "react";
import { EDIT_SUBADMIN, GET_SUBADMIN } from "../../queries/SubAdminQuery";
import { useMutation, useQuery } from "@apollo/client";
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';
import InlineLoader from "../utils/InlineLoader";

const EditViewSubAdminPage = ({pageType,onClose,subAdminId}) =>{
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        oldImageUrl: "", 
        newImageFile : ""
      });
      const [imagePreview, setImagePreview] = useState('');
      const [validationErrors, setValidationErrors] = useState({});

    const { data, loading, error, refetch } = useQuery(GET_SUBADMIN, {
        variables: { id: subAdminId },
    });

    useEffect(() => {
        refetch();
      }, [subAdminId, refetch]);


      const [editSubadminMutation, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(EDIT_SUBADMIN, {
        onCompleted: (data) => {
          ToastNotification({ message: data?.editSubadmin?.message, type: 'success' });
          onClose();
        },
        onError: (error) => {
          ToastNotification({ message: error.message, type: 'error' });
        }
      }
      );

    const { getSubadmin } = data || {}

    useEffect(() => {
        if (getSubadmin) {
          setFormData({  
            firstName: getSubadmin?.firstName || "",
            lastName: getSubadmin?.lastName || "",
            email: getSubadmin?.email || "",
            oldImageUrl: getSubadmin?.image || '',
            newImageFile: '',});
        }
        setImagePreview(getSubadmin?.image);
      }, [getSubadmin]);

    const handleChange = (e) => {
        const { name, value } = e.target; {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
            setFormData(prevState => ({ ...prevState, newImageFile: file }));
          };
          reader.readAsDataURL(file);
        }
      };

      const handleSubmit = async () => {
        try {
            const { data }  = await editSubadminMutation({
              variables: {
                id: subAdminId, 
                firstName: formData.firstName || "",
                lastName: formData.lastName || "",
                oldImageUrl: getSubadmin?.image || "", 
                newImageFile: formData.newImageFile || null
              }
            });
          } catch (error) {
            console.error('Error occurred:', error);
            ToastNotification({ message: error.message, type: 'error' });
          }
      };

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
          <Toaster position="top-right" reverseOrder={false} />
          <div className="w-[90%] sm:w-[640px] lg:w-[870px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 lg:pr-12 lg:py-11 lg:p-6 rounded-[10px] bg-white">
            <div className="flex justify-between items-center border-b border-[#E5E7EB] pb-6 mb-6">
              <div>
                <h3 className="font-semibold lg:font-bold text-lg lg:text-xl">{pageType === "view"? "Sub Admin" :"Edit Sub Admin"}</h3>
              </div>
              <button onClick={onClose} className="">
                <img src="/images/times_icon.svg" alt="" />
              </button>
            </div>
            {loading || error || !data ? 
                    loading ? (
                        <InlineLoader style={{width:"40px", height:"40px"}}/>
                    ) : error ? (
                        <p className='error_text'>Error: {error.message}</p>
                    ) : (
                        <p className='error_text' >
                            <img src="/images/empty_data.svg" alt=" " />
                        </p>
                        )
                :<>
            <div className="flex flex-wrap sm:flex-nowrap gap-4 sm:gap-6">
              <div className="w-24 sm:w-36 lg:w-48">
                <div className="relative w-24 h-24 sm:w-36 sm:h-36 lg:w-48 lg:h-48 border-dotted border-[2px] border-[#161941] rounded-sm">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
                    disabled={pageType === "view"}
                  />
                  {imagePreview ? (
                    <img className="w-full h-full object-cover rounded-sm" src={imagePreview} alt="" />
                  ) : (
                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <img className="w-8 sm:w-auto" src="/images/placeholder_img.svg" alt="" />
                    </span>
                  )}
                </div>
                <label className="block mt-2 text-center w-full text-[#6B7280] text-xs sm:font-semibold tracking-[1px]">
                  Upload Photo
                </label>
              </div>
              <div className="w-full sm:w-[calc(100%-144px)] lg:w-[calc(100%-192px)]">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 sm:gap-6 mb-2 sm:mb-6">
                  <div>
                    <label className="text-sm mb-1 sm:font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                      First Name
                    </label>
                    <input
                      className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                      value={formData.firstName}
                      onChange={handleChange}
                      disabled={pageType === "view"}
                    />
                  </div>
                  <div>
                    <label className="text-sm mb-1 sm:font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                      Last Name
                    </label>
                    <div className="relative">
                      <input
                        className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="Enter last name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        disabled={pageType === "view"}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-sm mb-1 sm:font-semibold tracking-[1px] text-[#757575]" htmlFor="">
                      Email
                    </label>
                    <div className="relative">
                      <input
                        className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={pageType === "view"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center mt-4 sm:mt-10">
              {pageType === "view"? '':
              <button onClick={handleSubmit} className="w-full sm:w-64 h-10 sm:h-12 bg-[#161941] rounded-md text-white sm:font-semibold lg:font-bold text-sm sm:text-base tracking-[1px]">
                {updateLoading ? <div className="upload_loader" style={{ marginLeft: "50%" }}></div> : "Save Changes"}
              </button>}
            </div></>}
          </div>
        </div>
      );
}

export default EditViewSubAdminPage;