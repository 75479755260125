import { gql } from '@apollo/client';

export const GET_ALL_ORDERS = gql`
  query GetAllOrders($page: Int!, $limit: Int!, $status: String!) {
    getAllOrders(page: $page, limit: $limit, status: $status) {
      total
      perPage
      currentPage
      totalPages
      orders {
        id
        orderId
        fistItemName
        totalItemsInOrder
        Amount
        orderPlaced
        buyerName
        orderCompleted
      }
    }
  }
`;

export const GET_ADMIN_BUYER_ORDERS = gql`
  query GetAdminBuyerOrders($page: Int, $limit: Int, $status: String, $buyerId: ID) {
    getAdminBuyerOrders(page: $page, limit: $limit, status: $status, buyerId: $buyerId) {
      total
      perPage
      currentPage
      totalPages
      orders {
        id
        orderId
        firstItemName
        totalItemsInOrder
        Amount
        orderPlaced
        orderCompleted
      }
    }
  }
`;

export const GET_ADMIN_BUYER_ORDER_DETAIL = gql`
  query AdminBuyerOrderDetail($id: ID!) {
    adminBuyerOrderDetail(id: $id) {
      id
      orderId
      status
      address
      delieveryEstimation
      delieveryEndTime
      delieveryStartTime
      totalAmount
      buyerName
      items {
        name
        catchId
        image
        price
        pricePerLbs
        weight
      }
    }
  }
`;

export const COMPLETE_ORDER_STATUS_QUERY = gql`
  query CompleteOrder($id: ID!, $status: String!) {
    completeOrder(id: $id, status: $status) {
      message
    }
  }
`;

export const EXPORT_ORDERS_QUERY = gql`
  query exportOrders($status: String!) {
    exportOrders(status: $status){
      path
    }
  }
`;