import { Link, useParams } from "react-router-dom";
import { GET_SELLER_CATCHES, GET_SELLER_REQUEST_BY_ID } from "../../queries/SellerQuery";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import InlineLoader from "../utils/InlineLoader";
import { useSelector } from "react-redux";
import moment from "moment";


const SellerDetailPage = () => {
  const { id } = useParams();
  const { currentPage } = useSelector((state)=> state.auth);
  const { loading: loadingSeller, error: errorSeller, data: dataSeller, refetch} = useQuery(GET_SELLER_REQUEST_BY_ID, {
    variables: { id },
  });

  
  const { loading: loadingCatches, error: errorCatches, data: dataCatches, refetch: refetchCatches  } = useQuery(GET_SELLER_CATCHES, {
    variables: {
      page: 1,
      limit: 15,
      sellerId: id,
      search: "blank",
    },
  });

  useEffect(() => {
    refetch();
    refetchCatches();
  }, [id, refetch, refetchCatches]);

  const { getSellerRequestDetail } = dataSeller || {};
  const getSellerCatches = dataCatches?.getSellerCatches || [];

  return (
    <>
      {loadingSeller || errorSeller || !dataSeller ? (
        <div className="p-4 lg:p-6 xl:p-12 bg-[#FAFBFF] h-screen flex justify-center items-center">
          {loadingSeller ? (
            <InlineLoader />
          ) : errorSeller ? (
            <p className='error_text'>Error: {errorSeller.message}</p>
          ) : (
            <p className='error_text'>No data available</p>
          )}
        </div>
      ) : (
        <div class="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]" >
          <div class="bg-white p-4 sm:p-5 lg:px-9 lg:py-7">
            <div class="mb-3 sm:mb-5 xl:mb-11">
              <h3 class="text-lg font-semibold lg:text-[22px] text-[#727272]">
                Personal Information
              </h3>
            </div>
            <div class="flex w-full flex-wrap xl:flex-nowrap items-start gap-4">
              <div class="w-20 h-20 sm:w-28 sm:h-28">
                <img
                  class="w-full h-full object-cover rounded-md"
                  src={getSellerRequestDetail?.personalInfo?.image}
                  alt=""
                />
              </div>
              <div class="w-full xl:w-[calc(100%-114px)]">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 gap-6 mb-6">
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Name
                    </label >

                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                      type="text"
                      placeholder="Henry Estward"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.personalInfo?.name}
                    />
                  </div>
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Email</label>
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                      type="email"
                      placeholder="abcde@gmail.com"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.personalInfo?.email}
                    />
                  </div>
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Mobile Number</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="+91 1234567890"
                      name=""
                      id=""
                      value={`${getSellerRequestDetail?.personalInfo?.phoneNumber?.dialCode || ''} ${getSellerRequestDetail?.personalInfo?.phoneNumber?.number || ''}`}
                    />
                  </div>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Address</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                      type="text"
                      placeholder="Address"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.personalInfo?.address}
                    />
                  </div>
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Bio</label
                    >
                    <textarea
                      class="border-none placeholder:text-[#4B5563] text-left font-medium bg-[#F6F6F6] text-base text-[#4B5563] pt-3 px-3 h-[80px] sm:h-[115px] rounded-[10px] w-full"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.personalInfo?.bio}
                    >
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                    </textarea
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white p-4 sm:p-5 lg:px-9 lg:py-7 mt-5 sm:mt-10 mb-5 sm:mb-12">
            <div class="mb-3 sm:mb-5 xl:mb-11">
              <h3 class="text-lg font-semibold lg:text-[22px] text-[#727272]">
                Company Information
              </h3>
            </div>
            <div class="flex w-full flex-wrap xl:flex-nowrap items-start gap-4">
            {getSellerRequestDetail?.companyInfo?.logo && 
            <div class="w-20 h-20 sm:w-28 sm:h-28">
                <img
                  class="w-full h-full object-cover rounded-md"
                  src={getSellerRequestDetail?.companyInfo?.logo}
                  alt=""
                />
              </div>}
              <div class="w-full xl::w-[calc(100%-114px)]">
                <div
                  class="flex flex-wrap md:justify-between lg:justify-normal gap-4 mb-6 sm:mb-6"
                >
                  {getSellerRequestDetail?.companyInfo?.name &&
                    <div>
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for=""
                      >Company Name</label
                      >
                      <input
                        class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                        type="text"
                        placeholder="TACO BELLS"
                        name=""
                        id=""
                        value={getSellerRequestDetail?.companyInfo?.name}
                      />
                    </div>
                  }
                  {getSellerRequestDetail?.companyInfo?.website &&
                    <div>
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for=""
                      >Company’s Website</label
                      >
                      <input
                        class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="text"
                        placeholder="tacobells.co.in"
                        name=""
                        id=""
                        value={getSellerRequestDetail?.companyInfo?.website}
                      />
                    </div>
                  }
                  {getSellerRequestDetail?.companyInfo?.species?.length > 0 &&
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Target Species Landed</label
                    >
                    <div class="flex flex-wrap items-center xl:h-[54px] gap-3">
                      {getSellerRequestDetail?.companyInfo?.species.map((specie) =>
                        <button className="py-2 sm:py-3 px-5 rounded-md bg-[#3D4289] text-xs font-normal sm:font-bold tracking-[1px] text-white">
                          {specie}
                        </button>
                      )}
                    </div>
                  </div>
                  }
                </div>
                <div
                  class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 mb-4 sm:mb-6"
                >
                   {getSellerRequestDetail?.companyInfo?.state && 
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >State License</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3 rounded-[10px] w-full"
                      type="text"
                      placeholder="State Name"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.companyInfo?.state}
                    />
                  </div>
                   }
                   {getSellerRequestDetail?.companyInfo?.stateLicenseNumber &&
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >License Number</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                      type="text"
                      placeholder="License Number"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.companyInfo?.stateLicenseNumber}
                    />
                  </div>
                   }
                   {getSellerRequestDetail?.companyInfo?.federalLicenseNumber &&
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >Federal License</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                      type="text"
                      placeholder="License Number"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.companyInfo?.federalLicenseNumber}
                    />
                  </div>
                   }
                   {getSellerRequestDetail?.companyInfo?.ein &&
                  <div>
                    <label
                      class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      for=""
                    >EIN or SS#</label
                    >
                    <input
                      class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                      type="text"
                      placeholder="AA 11 A 1234"
                      name=""
                      id=""
                      value={getSellerRequestDetail?.companyInfo?.ein}
                    />
                  </div>
                   }
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white p-4 sm:p-5 lg:px-9 lg:py-7 mt-5 sm:mt-10 mb-12">
            <div class="mb-3 sm:mb-5 xl:mb-11 flex justify-between items-center">
              <h3 class="text-base font-semibold lg:text-[22px] text-[#727272]">
                Seller’s Catch
              </h3>
              <a href={`/sellers-catches/${id}/${currentPage}`} class="text-[#161941] text-base sm:text-[22px] font-semibold">See all</a>
            </div>
            <div class="bg-white h-[45vh] sm:h-[50vh] lg:h-[65vh] overflow-y-auto">
              <div class="overflow-x-auto">
                <table class="w-full min-w-[900px]">
                  <thead>
                    <tr>
                      <th
                        class="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left"
                      >
                        Fish Type
                      </th>
                      <th
                        class="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md"
                      >
                        Weight
                      </th>
                      <th
                        class="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left"
                      >
                        Tag Number
                      </th>
                      <th
                        class="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left"
                      >
                        Date
                      </th>
                      <th
                        class="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingCatches || errorCatches || getSellerCatches?.sellerCatches?.length === 0 &&
                      <tr>
                        <td colSpan={7} style={{ height: 450 }}>
                          {loadingCatches ? (
                            <InlineLoader />
                          ) : errorCatches ? (
                            <p className='error_text'>Error: {errorCatches.message}</p>
                          ) : (
                            <p className='error_text' >
                              <img src="/images/approved_seller_image.svg" alt=" " />
                            </p>
                          )}
                        </td>
                      </tr>
                    }
                    {getSellerCatches?.sellerCatches?.map((sellerCatche) =>
                      <tr>
                        <td class="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <div class="flex items-center gap-[20px]">
                            <a href="" class="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-[10px] bg-gray-200">
                              {loadingCatches ?
                               <div className='shimmer w-full h-full rounded-[10px] object-cover block'></div>
                              :<img
                                src={sellerCatche?.image}
                                alt=""
                                class="w-full h-full rounded-[10px] object-cover block"
                              />
                              }
                            </a>
                            <span class="font-medium text-base text-left">{sellerCatche?.name}</span>
                          </div>
                        </td>
                        <td class="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span class="font-medium text-base text-left"
                          >{sellerCatche?.weight+ "lbs"}</span>
                        </td>
                        <td class="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span class="font-medium text-base text-left"
                          >{sellerCatche?.tagNumber}</span
                          >
                        </td>
                        <td class="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span class="font-medium text-base text-left">{moment(sellerCatche?.dateTime).format('MM/DD/YYYY')}
                            {/* <div class="text-xs text-[#828282] block pl-4 pt-1">10:12 AM</div> */}
                          </span>
                        </td>
                        <td
                          class="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right"
                        >
                          <Link to={`/seller/catch-details/${sellerCatche?.id}`}>
                            <button class="text-base w-auto">
                              <img src="/images/action_eye_icon.svg" alt="" />
                            </button>
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
};

export default SellerDetailPage;