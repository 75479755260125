import React, { Suspense } from 'react';
import {Route, Routes } from 'react-router-dom';
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import VerifyMail from "./components/VerifyMail";
import AllRoutes from './components/routes/AllRoutes';
import "./styles/css/style.css";
import { InMemoryCache } from '@apollo/client';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-mail" element={<VerifyMail />} />
      </Routes>
      <Suspense fallback={null}>
        <AllRoutes />
      </Suspense>
    </>
  );
}

export default App;
