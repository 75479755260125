import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA = gql`
  query GetDashboardData {
    getDashboardData {
      totalSales
      totalFishSpecies
      totalBuyers
      totalSellers
    }
  }
`;