import { gql } from '@apollo/client';

export const GET_FISH_INVENTORIES = gql`
  query GetFishInventories {
    getFishInventories {
      id
      speciesName
      buyerPrice
      sellerPrice
      image
      isHide
      quantityInStock
    }
  }
`;

export const GET_CATCHES_BY_FISH = gql`
  query GetCatcheByFish($page: Int!, $limit: Int!, $fishId: ID!, $search: String) {
    getCatcheByFish(page: $page, limit: $limit, fishId: $fishId, search: $search) {
      total
      perPage
      currentPage
      totalPages
      catches {
        id
        sellerName
        tagNumber
        dateTime
        weight
      }
    }
  }
`;

export const FISH_INVENTORY_MUTATION = gql`
  mutation FishInventory($speciesName: String!, $sellerPrice: Float!, $buyerPrice: Float!, $image: Upload!) {
    fishInventory(input: {speciesName: $speciesName, sellerPrice: $sellerPrice, buyerPrice: $buyerPrice, image: $image}) {
      message
    }
  }
`;

export const EDIT_FISH_INVENTORY_MUTATION = gql`
  mutation EditFishInventory($id: ID!, $speciesName: String!, $buyerPrice: Float!, $sellerPrice: Float!, $oldImageUrl: String!, $newImageFile: Upload) {
    editFishInventory(id: $id, input: {speciesName: $speciesName, buyerPrice: $buyerPrice, sellerPrice: $sellerPrice, oldImageUrl: $oldImageUrl, newImageFile: $newImageFile}) {
      message
    }
  }
`;

export const GET_FISH_INVENTORY = gql`
  query GetFishInventory($id: ID!) {
    getFishInventory(id: $id) {
      id
      speciesName
      buyerPrice
      sellerPrice
      image
    }
  }
`;

export const HIDE_INVENTORY = gql`
mutation HideUnhideFishInventory($id: ID!, $status: Boolean!) {
  hideUnhideFishInventory(id: $id, status: $status) {
      message
  }
}
`;

export const EXPORT_FISH_INVENTORY_QUERY = gql`
  query exportFishInventories {
    exportFishInventories {
      path
    }
  }
`;