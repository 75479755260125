import loaderClasses from './loader.module.css'

const InlineLoader = ({ width = '100%', height = '100%' }) => {
  return (
    <>
      <div
        style={{ width: width, height: height, display: "flex", justifyContent: "center",alignItems: "center" }}>
        <div className={loaderClasses.loadingioSpinnerRolling}>
          <div className={loaderClasses.ldioLoader}>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InlineLoader
