import {useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GET_ADMIN_BUYER_ORDERS } from '../../queries/BuyerQuery';
import InlineLoader from '../utils/InlineLoader';
import ToastNotification from '../../utils/ToastNotification';
import { Toaster } from 'react-hot-toast';
import moment from 'moment';

const BuyerOrderDetailPage = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const limit = 15;
  const [activeTab, setActiveTab] = useState("pending");
  

  const  { loading: orderLoading, error: orderError, data: orderData } = useQuery(GET_ADMIN_BUYER_ORDERS, {
    variables: { page:page, limit:limit, status:activeTab, buyerId: id },
    onCompleted: (data) => {
      ToastNotification({ message: 'Buyers orders fetched successfully', type: 'success' });
    },
    onError: (err) => {
      ToastNotification({ message: err.message, type: 'error' });
    }
  });

  const { getAdminBuyerOrders } = orderData || {}

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="grid grid-cols-1 lg:grid-cols-1 mb-6 rounded-md overflow-hidden">
        <div className="relative">
          <div className="w-full">
            <div className="flex px-8 bg-white">
              <button
                 onClick={() => setActiveTab("pending")}
                className={`w-34 relative font-medium text-xl py-5 px-10 pl-0 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  activeTab === "pending"? 'bg-transparent text-[#161941] dropline' : 'text-[#828282]'
                }`}
              >
                Pending
              </button>
              <button
                 onClick={() => setActiveTab("completed")}
                className={`w-34 relative font-medium text-xl py-5 px-10 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  activeTab === "completed" ? 'bg-transparent text-[#161941] dropline' : 'text-[#828282]'
                }`}
              >
                Completed
              </button>
            </div>

            {activeTab === "pending" && (
              <div className="transition-all duration-300 bg-white">
                <div className="bg-white h-[65vh] overflow-y-auto">
                  <div className="overflow-x-auto">
                    <table className="w-full min-w-[640px]">
                      <thead>
                        <tr>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order ID
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                            Fish Type
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Amount
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order Placed
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {orderLoading || orderError || !orderData ? (
                          <tr>
                            <td colSpan={7} style={{ height: 450 }}>
                              {orderLoading ? (
                                <InlineLoader />
                              ) : orderError ? (
                                <p className='error_text'>Error: {orderError.message}</p>
                              ) : (
                                <p className='error_text' >
                                  <img src="/images/buyers_image.svg" alt=" " />
                                </p>
                              )}
                            </td>
                          </tr>
                        ) : ""}
                      {getAdminBuyerOrders?.orders?.map((order)=>
                       <tr>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{order?.orderId}</span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {order?.fistItemName}
                         </span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{`$${(parseFloat(order?.Amount) || 0).toFixed(2)}`}</span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {moment(order?.orderPlaced).utc().format("MM/DD/yyyy")}
                           <div className="text-xs text-[#828282] block pl-4 pt-1"> {order?.orderPlaced?.split(' ')[1]}</div>
                         </span>
                       </td>
                       <td className="py-5 px-11 border-b border-b-[#EDEDED] text-right">
                       <Link to={`/orders/${activeTab}-details/${order?.id}`}>
                          <button className="text-base w-auto">
                            <img src="/images/action_eye_icon.svg" alt="View" />
                          </button>
                        </Link>
                       </td>
                     </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "completed" && (
              <div className="transition-all duration-300 bg-white">
                <div className="bg-white h-[65vh] overflow-y-auto">
                  <div className="overflow-x-auto">
                    <table className="w-full min-w-[640px]">
                      <thead>
                        <tr>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order ID
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                            Fish Type
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Amount
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                            Order Placed
                          </th>
                          <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {getAdminBuyerOrders?.orders?.map((order)=>
                       <tr>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{order?.orderId}</span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {order?.fistItemName}
                         </span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">{`$${(parseFloat(order?.Amount) || 0).toFixed(2)}`}</span>
                       </td>
                       <td className="py-5 px-7 border-b border-b-[#EDEDED]">
                         <span className="font-medium text-base text-left">
                         {order?.orderPlaced?.split(' ')[0]}
                           <div className="text-xs text-[#828282] block pl-4 pt-1"> {order?.orderPlaced?.split(' ')[1]}</div>
                         </span>
                       </td>
                       <td className="py-5 px-11 border-b border-b-[#EDEDED] text-right">
                       <Link to={`/orders/${activeTab}-details/${order?.id}`}>
                          <button className="text-base w-auto">
                            <img src="/images/action_eye_icon.svg" alt="View" />
                          </button>
                        </Link>
                       </td>
                     </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerOrderDetailPage;
