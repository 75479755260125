import { Link } from "react-router-dom";
import { GET_APPROVED_SELLER } from "../../queries/SellerQuery";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import FooterPage from "./FooterPage";
import InlineLoader from "../utils/InlineLoader";
import { useSelector } from 'react-redux';

const SellersPage = () => {
    const { currentPage } = useSelector((state)=> state.auth);
    const [page, setPage] = useState(currentPage);
    const [searchString, setSearchString] = useState("blank");
    const limit = 15;


    const { loading, error, data, refetch } = useQuery(GET_APPROVED_SELLER, {
        variables: { limit, page, search: searchString },
    });

    useEffect(() => {
        refetch();
      }, [page, refetch]);

    const { getApprovedSellers } = data || {};

    return (
        <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
            <div className="grid grid-cols-1 lg:grid-cols-1 mb-6">
                <div className="w-full border-b border-b-[#EDEDED] p-4 sm:py-[15px] sm:px-[30px] bg-white flex items-center justify-between">
                    <div className="relative w-full sm:w-[300px]">
                        <input className="text-xs font-medium px-9 w-full h-[40px] bg-[#FDFDFD] border border-solid border-[#E0E0E0] rounded-[10px]" type="search" placeholder="Search by name" onChange={(e) => setSearchString(e.target.value)} />
                        <span className="absolute top-1/2 -translate-y-1/2 left-[15px]">
                            <img src="/images/search_icon.svg" alt="Search" />
                        </span>
                    </div>
                </div>
                <div className="bg-white h-[45vh] sm:h-[50vh] lg:h-[65vh] mb-0 overflow-y-auto">
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[900px]">
                            <thead>
                                <tr>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">Name</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">Email</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">Mobile Number</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-left">EIN or SS#</th>
                                    <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-black py-4 sm:py-[20px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading || error || getApprovedSellers?.approvedSellers?.length === 0 ? (
                                    <tr>
                                        <td colSpan={7} style={{ height: 450 }}>
                                            {loading ? (
                                                <InlineLoader />
                                            ) : error ? (
                                                <p className='error_text'>Error: {error.message}</p>
                                            ) : (
                                                <p className='error_text' >
                                                    <img src="/images/approved_seller_image.svg" alt=" " />
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                ) :
                                getApprovedSellers?.approvedSellers.map((approvedSeller) =>
                                    <tr>
                                        <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                                            <div className="flex items-center gap-[20px]">
                                                {loading ?
                                                    <div className='shimmer w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-[10px] object-cover block'></div>
                                                    : <img src={approvedSeller?.image} alt="Henry Estward" className="w-[40px] h-[40px] sm:w-[50px] sm:h-[50px] rounded-[10px] object-cover block" />}
                                                <span className="font-medium text-sm sm:text-base text-left">{approvedSeller?.name}</span>
                                            </div>
                                        </td>
                                        <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{approvedSeller?.email}</span>
                                        </td>
                                        <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{approvedSeller?.phoneNumber?.dialCode}{approvedSeller?.phoneNumber?.number}</span>
                                        </td>
                                        <td className="py-3 sm:py-4 px-7 border-b border-b-[#EDEDED]">
                                            <span className="font-medium text-sm sm:text-base text-left">{approvedSeller?.ein}</span>
                                        </td>
                                        <td className="py-3 sm:py-4 px-11 border-b border-b-[#EDEDED] text-right">
                                            <Link to={`/sellers/details/${approvedSeller.id}`}>
                                                <button className="text-sm sm:text-base w-auto">
                                                    <img src="/images/action_eye_icon.svg" alt="View" />
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <FooterPage
                    currentPage={page}
                    totalPages={getApprovedSellers?.totalPages}
                    onPageChange={setPage}
                    perPage={getApprovedSellers?.approvedSellers?.length}
                    totalValue = {getApprovedSellers?.total}
                />
            </div>
        </div>
    );
};

export default SellersPage;
