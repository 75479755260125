import React, { useState, useEffect } from 'react';
import { EDIT_FISH_INVENTORY_MUTATION, FISH_INVENTORY_MUTATION, GET_FISH_INVENTORIES, GET_FISH_INVENTORY } from '../../queries/FistInventoryQuery';
import { useMutation, useQuery } from '@apollo/client';
import toast, { Toaster } from 'react-hot-toast';
import ToastNotification from '../../utils/ToastNotification';
import InlineLoader from '../utils/InlineLoader';

const EditSpeciesForm = ({ pageType, onClose, fishId }) => {
    const { data, loading, error, refetch } = useQuery(GET_FISH_INVENTORY, {
        variables: { id: fishId },
    });


  useEffect(() => {
    refetch();
  }, [fishId, refetch]);


    const [editFishInventoryMutation, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(EDIT_FISH_INVENTORY_MUTATION, {
        onCompleted: (data) => {
            ToastNotification({ message: data?.editFishInventory.message, type: 'success' });
            setTimeout(() => {
                onClose();
            }, 1000);
        },
        onError: (err) => {
            ToastNotification({ message: err.message, type: 'error' });
            setTimeout(() => {
                onClose();
            }, 1000);
        }
    });

    const initialFormData = {
        speciesName: '',
        sellerPrice: '',
        fishPhoto: '',
        oldImageUrl: '',
        buyerPrice: '',
    }

    const [formData, setFormData] = useState(initialFormData);
    const [imagePreview, setImagePreview] = useState('');

    const { getFishInventory } = data || {};

    useEffect(() => {
        if (getFishInventory && (pageType === 'edit' || pageType === 'view')) {
            const updatedInventory = {
                ...getFishInventory,
                buyerPrice: parseFloat(getFishInventory.buyerPrice).toFixed(2),
                sellerPrice: parseFloat(getFishInventory.sellerPrice).toFixed(2),
            };
            setFormData(updatedInventory);
            setImagePreview(updatedInventory.image);
        }
    }, [getFishInventory, pageType]);

    const handleEditFishInventory = async () => {
        try {
            const { data } = await editFishInventoryMutation({
                variables: {
                    id: fishId,
                    speciesName: formData.speciesName,
                    buyerPrice: parseFloat(formData.buyerPrice),
                    sellerPrice: parseFloat(formData.sellerPrice),
                    oldImageUrl: getFishInventory.image,
                    newImageFile: formData.fishPhoto || null
                }
            });
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setFormData(prevState => ({ ...prevState, fishPhoto: file }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pageType === 'edit') {
            handleEditFishInventory();
        }
    };


    return (

        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
            <Toaster position="top-right" reverseOrder={false} />
            <div className="w-[80%] lg:w-[870px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white p-4 sm:pr-12 sm:py-11 sm:p-6 rounded-[10px]">
                <button className="absolute top-4 right-4" onClick={() => onClose()}>
                    <img src="/images/times_icon.svg" alt="Close" />
                </button>
                {loading || error || !data ? 
                    loading ? (
                        <InlineLoader style={{width:"40px", height:"40px"}}/>
                    ) : error ? (
                        <p className='error_text'>Error: {error.message}</p>
                    ) : (
                        <p className='error_text' >
                            <img src="/images/empty_data.svg" alt=" " />
                        </p>
                        )
                :
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap sm:flex-nowrap gap-y-4 justify-between items-center border-b border-[#E5E7EB] pb-4 sm:pb-6 mb-4 sm:mb-6">
                        <div className='w-full sm:w-auto'>
                            <h3 className="font-bold text-lg sm:text-xl">
                                {pageType === 'add' ? 'Add New Species' : pageType === 'edit' ? 'Edit Species' : 'View Species'}
                            </h3>
                            <p className="text-[#4B5563] text-sm sm:text-base">
                                {pageType === 'add' ? 'Easily add new fish species' : pageType === 'edit' ? 'Edit the details of the species' : 'View the details of the species'}
                            </p>
                        </div>
                        {pageType !== 'view' && (
                            <button type="submit" className="h-[35px] sm:h-auto sm:py-4 px-3 text-xs sm:px-6 bg-[#161941] rounded-md text-white font-normal sm:font-bold sm:text-base">
                                {updateLoading ? (
                                    <div className="upload_loader" style={{marginLeft:"0%"}}></div>
                                ) : (
                                    pageType === 'edit' ? 'Save Changes' : ''
                                )}
                            </button>
                        )}
                    </div>
                    <div className="flex gap-4 sm:gap-6 flex-wrap sm:flex-nowrap">
                        <div className="w-20 md:w-40 lg:w-48">
                            <div className="relative w-full h-20  md:h-40 lg:h-48 border-dotted border-[2px] border-[#161941] rounded-sm">
                                <input
                                    type="file"
                                    className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
                                    onChange={handleFileChange}
                                    disabled={pageType === 'view'}
                                />
                                {imagePreview ? (
                                    <img className="w-full h-full object-contain  rounded-sm" src={imagePreview} alt="Fish" />
                                ) : (
                                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <div className='shimmer' style={{ width: "190px", height: "190px" }}></div>
                                    </span>
                                )}
                            </div>
                            <label className="block mt-2 text-center w-full text-[#6B7280] text-xs font-semibold tracking-[1px]">
                                Fish Photo
                            </label>
                        </div>
                        <div className="w-full md:w-[calc(100%-120px)] lg:w-[calc(100%-192px)]">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 mb-6">
                                <div>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="speciesName">
                                        Species Name
                                    </label>
                                    <input
                                        className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                        type="text"
                                        placeholder="Name"
                                        name="speciesName"
                                        value={formData.speciesName}
                                        onChange={handleInputChange}
                                        disabled={pageType === 'view'}
                                    />
                                </div>
                                <div>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="sellerPrice">
                                        Seller Price
                                    </label>
                                    <div className="relative">
                                        <span className="absolute top-1/2  text-[#757575] text-lg tracking-[1px]" style={{margin: "-15px 3px"}}>$</span>
                                        <input
                                            className="border-none font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-4 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="111"
                                            name="sellerPrice"
                                            value={formData.sellerPrice}
                                            onChange={handleInputChange}
                                            disabled={pageType === 'view'}
                                        />
                                        <span className="absolute top-1/2 right-2 -translate-y-1/2 text-[#757575] text-sm tracking-[1px]">
                                            / lbs
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]" htmlFor="sellerPrice">
                                        Buyer Price
                                    </label>
                                    <div className="relative">
                                    <span className="absolute top-1/2  text-[#757575] text-lg tracking-[1px]" style={{margin: "-15px 3px"}}>$</span>
                                        <input
                                            className="border-none font-medium bg-[#F6F6F6] text-lg sm:text-base text-[#4B5563] px-4 h-[45px]  sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                                            type="text"
                                            placeholder="111"
                                            name="buyerPrice"
                                            value={formData.buyerPrice}
                                            onChange={handleInputChange}
                                            disabled={pageType === 'view'}
                                        />
                                        <span className="absolute top-1/2 right-2 -translate-y-1/2 text-[#757575] text-sm tracking-[1px]">
                                            / lbs
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                }
            </div>
        </div>

    );
};

export default EditSpeciesForm;
